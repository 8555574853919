import React, { FC, useEffect, useRef, useState } from 'react';
import { Sound, SoundPlayer } from '../classes/sound-player';

import { Menu } from './Menu';
import { MyScene } from '../classes/my-scene';
import { SocketClient } from '../classes/socket-client';
import { api } from '../utils/api';
import { mapNames } from '../utils/constants';

export const Game: FC = () => {
  const ref = useRef<HTMLCanvasElement>(null);
  const queryParams = MyScene.getQueryParams();
  const [mapIndex, play] = useState(Number(queryParams.level ?? -1));
  const [name, setName] = useState(queryParams.name || '');

  useEffect(() => {
    const { isInitialized } = api.scene?.pixi || {};
    const canvas = ref.current;
    const gtag = globalThis.gtag;

    if (!isInitialized && mapIndex !== -1 && canvas) {
      SoundPlayer.play(Sound.BUTTON);

      MyScene.create(mapIndex, canvas, name).then((scene) => {
        api.scene = scene;
        api.client = new SocketClient(scene);
        api.client!.login(mapIndex, scene.player!);
      });

      if (gtag) {
        gtag('event', 'play', {
          event_category: 'game_event',
          event_label: mapNames[mapIndex],
          href: location.href,
          name
        });
      }
    }
  }, [ref.current, mapIndex, name]);

  return (
    <>
      <main id="canvas">
        <canvas ref={ref} />
      </main>
      {mapIndex === -1 ? (
        <Menu name={name} setName={setName} play={play} />
      ) : null}
    </>
  );
};
