import { AnimationConfig, AnimationKeys } from './model';

import { Vector } from 'detect-collisions';
import commonSpriteSheet from '../assets/spritesheet-common.json';
import { getAnimations } from './texture-cache';

export const hitByItemDistance = 21;

export enum Skills {
  CRY_AND_RUN = 0,
  BURNING_DELIVERY = 1,
  EDGE_OF_IRRITATION = 2,
  POKE_OF_ODIN = 3
}

export const beardFrames = [
  311, 65, 66, 67, 68, 77, 78, 79, 80, 89, 90, 91, 92, 102, 103, 104, 114, 115,
  116, 126, 127
];

export const auraFrames = {
  player: {
    prep: [32, 33, 34, 35],
    punch: [21, 22, 23]
  },
  boss: {
    prep: [159, 160, 161],
    punch: [171],
    tired: [185, 186]
  }
};

export const playerParticles = [
  'walk',
  'hit',
  'dash',
  'death1',
  'death2',
  'death3',
  'death4',
  'life',
  'berserk',
  'beesPU',
  'thorPU'
];

export const beardNames = [
  'Spanish Mustache',
  'This is a joke',
  'No beard at all',
  'True Hipster',
  "Dwarf's Wife",
  'Lumberjack',
  'Almost Viking',
  'Young Santa',
  'Fancy Homeless',
  'Belly Cape',
  'Bloody Waterfall',
  'Bride Braids',
  'Feet Toucher',
  'Ground Sweeper',
  'Siege Evening Dress',
  'Red Chain Armor',
  'Big Hairy Bag',
  'Bear on the Chin',
  "Pirate king's beard",
  'Full Time Odin',
  'Soul of the Ginger'
];

export const achievementsNames = {
  blocked: ["Can't touch this", 'Block $ punches'],
  combo: ['Brutal chain reaction', 'K.O. $ enemies in a row'],
  punch: ['Deadly Poke', 'K.O. $ enemies with punch'],
  maxPunch: ['Bone Crusher', 'K.O. $ enemies with maxed out punch'],
  skill2: ['Demolition Expert', 'K.O. $ with flaming object'],
  skill4: ['Teslas Forefather', "K.O. $ with odin's thunder"],
  chicken: ["“Don't make me throw the chicken”", 'K.O. $ enemies with chicken'],
  barrel: ['Kong impostor', 'K.O. $ with barrel'],
  chair: ['Chair on the face', 'K.O. $ with chair'],
  beers: ['Boozer', 'Drink $ beers'],
  powerupMeat: ['Power Meal', 'Eat $ meats'],
  powerupThunder: ['Condensator', 'Hit thor powerstone $ times'],
  powerupBee: ['Dances with Bees', 'Hit bee hive $ times'],
  default: ['New skill', '???']
};

export const soundConfig = {
  button: 'button',
  powerupThunder: 'thor1',
  powerupMeat: 'meat1',
  powerupBee: 'bees1',
  berserk: 'berzerk',
  dash: 'dash',
  block: 'block',
  chicken: 'chicken',
  wood: 'break-wood',
  boom: 'boom',
  thor0: 'thunder1',
  thor1: 'thunder2',
  punch0: 'punch1',
  punch1: 'punch2',
  punch2: 'punch3',
  laugh0: 'laugh1',
  laugh1: 'laugh2',
  laugh2: 'laugh3',
  laugh3: 'laugh4',
  throw: 'throw',
  beer: 'drink-beer',
  coin: 'coin'
};

export const deadStates = ['dying', 'died', 'ghost'];

export const hitStates = ['hit', 'struck'];

export const tapStates = ['prep', 'block', 'blocked'];

export const runStates = ['idle', 'run'];

export const walkStates = ['prep', 'carry'];

export const targetlessStates = ['dropped', 'idle', 'ghost'];

export const sinkItemStates = ['sink', 'ghost'];

export const deadItemStates = ['ghost'];

export const explodeItemStates = ['explode'];

export const ownerlessItemStates = [
  'dropped',
  'idle',
  'sink',
  'explode',
  'ghost'
];

export const droppedItemStates = ['dropped'];

export const noPushbackItemStates = ['carry', 'ghost'];

export const allowDropItemStates = ['carry', 'fly'];

export const triggerItemStates = [
  'carry',
  'dropped',
  'fly',
  'sink',
  'explode',
  'ghost'
];

export const mapNames = ['folk-concert', 'latrine-domination'];

export enum Constants {
  MIN_SOUND_LEVEL = 0.1,
  THROW_ITEM_MS = 400,
  EXPLODE_ITEM_MS = 350,
  DROPPED_ITEM_MS = 700,
  RESPAWN_ITEM_MS = 10_000,
  CARRY_ANCHOR_Y = 0.725,
  NON_STATIC_MULTIPLIER = 0.667,
  MAP_1_PLAYERS = 8,
  MAP_2_TEAM_PLAYERS = 5,
  MIN_PX_IDLE_TO_RUN = 5,
  STEP_MULTIPLIER = 2,
  PADDING_MULTIPLIER = 0.33,
  PLAYER_TINT = 0xffb040,
  TEAM_RED_TINT = 0xff5530,
  TEAM_BLUE_TINT = 0x40aaf8,
  NAME_FONT_SIZE = 11,
  UNTAP_CHANCE = 0.5,
  DIED_ALPHA = 0.66,
  PLAYER_AI_CHANCE = 0.4,
  ALL_AI_CHANCE = 0.67,
  BUTTON_RATIO = 0.67,
  RANGE_SIZE = 55,
  ALPHA_FADE = 1 / 3,
  HEART_FADE = 1 / 25,
  DASH_DISTANCE = 500,
  SPLASH_LIFETIME = 1_200,
  DEAD_ROTATION = -Math.PI / 2,
  BEARD_ROTATION_DELAY = 150,
  BEARD_ROTATION_DAMP = 0.015,
  BOOM_DISAPPEAR = 200,
  PUNCH_ONE_LAYER = 400,
  PUNCH_POWER_MAX = 3,
  GAME_FRAME = 58 / 1_000,
  TOILET_LEVEL_TIME = 5 * 60 * 1_000 + 10_000,
  TOILET_CONTEST_SQ = 160 * 160,
  NEAR_TARGET = 10,
  THROTTLE_SERVER_LOG = 10_000,
  THROTTLE_UPDATE = 400,
  SCENE_UPDATE_THROTTLE = 100,
  UPDATE_TOILET_THROTTLE = 10_000,
  FULLSCREEN_CLICK_THROTTLE = 1_000,
  BOSS_RESPAWN = 60_000,
  COMBO_TIMEOUT = 6_000,
  BLOCK_TIMEOUT = 500,
  POWERUP_DURATION = 10_000,
  POWERUP_COOLDOWN = 30_000,
  COIN_FADEOUT_AFTER = 1_000,
  ITEM_PICKABLE_AFTER = 400,
  ITEM_CLEANUP_AFTER = 30_000,
  ITEM_EXPLODE_AFTER_DROP = 3_000,
  ITEM_RESPAWN_AFTER_DEATH = 9_000,
  BERSERK_HEAL_TICK = 1_000,
  CHICKEN_PICKABLE_AFTER_DROP = 500,
  REPEAT_HIT = 500,
  MOUSE_LIMIT = 4,
  MOUSE_RANGE = 400,
  MAX_VOLUME = 0.5,
  MAX_TARGET_DISTANCE = 1024,
  PUSH_BACK_RANGE = 20,
  CAMERA_ZOOM = 1 / 540,
  CAMERA_SPEED = 1 / 7_500,
  ANIM_SPEED = 10_000 / 80,
  ANIM_SPEED_PREP = 10_000 / 70,
  TILE_SCALE = 43 / 42,
  ANIMATION_ZINDEX = 0.25,
  ANIMATION_ANCHOR_Y = 1,
  COLLIDER_OFFSET_Y = 0.05,
  NAME_OFFSET_Y = -32,
  HEART_OFFSET_Y = -30,
  DEFAULT_RADIUS = 7.5,
  RADIUS_Y = 0.8,
  MIN_SCALE = 3,
  DYING_STATE_DURATION = 400,
  DIED_STATE_DURATION = 1_000 * 3,
  GHOST_STATE_DURATION = 500,
  PLAYER_BLOCK_DURATION = 250,
  PLAYER_HIT_DURATION = 500,
  PLAYER_TIRED_DURATION = 350,
  PLAYER_DEAD_DELAY = 3_000,
  PLAYER_RESPAWN_AFTER_DEATH = 9_000,
  PLAYER_BERSERK_DURATION = 5_000,
  PUNCH_DURATION_1STR = 100,
  FPS_60_MS = 1_000 / 60,
  TIMEOUT_SERVER_SOCKET_MS = 30_000,
  SERVER_FRAME_MS = 25,
  MAX_MOVE_SPEED_PX = 3,
  MAX_BEARD_LENGTH = 20,
  MAX_HAT_INDEX = 48
}

export const animatorAnchor: Record<AnimationKeys, Vector> = {
  beczki: { x: 0.5, y: 0.55 },
  krzesla: { x: 0.5, y: 0.55 },
  chicken: { x: 0.5, y: 0.6 },
  beer: { x: 0.5, y: 0.6 },
  coin: { x: 0.5, y: 0.6 },
  baby: { x: 0.5, y: 0.75 },
  hero0: { x: 0.5, y: 0.7 },
  hero1: { x: 0.5, y: 0.7 },
  thunder: { x: 0.5, y: 1 }
};

export const skipWaterTilesGids = [
  0, 28, 31, 32, 70, 82, 114, 126, 129, 130, 131, 132, 163, 175, 207, 211, 223,
  227, 228, 235, 239, 240, 241, 253, 272, 273, 274, 275, 276, 284, 285, 286,
  287, 288, 292, 293, 294, 295, 296, 297, 298, 299, 300
];

export const animatorConfig: Record<AnimationKeys, AnimationConfig> = {
  beer: {
    idle: [138],
    take: [140, 141, 142, 143],
    ghost: [311]
  },
  thunder: {
    hit: [0, 1, 2],
    idle: [3, 5, 4, 5, 3, 4, 3, 4, 5]
  },
  baby: {
    idle: [146],
    run: [145, 146, 147],
    beer: [148, 149, 150]
  },
  coin: {
    idle: [255, 256, 257, 258, 259],
    take: [259, 260, 261, 262, 263],
    ghost: [311]
  },
  chicken: {
    run: [39, 40, 41, 40],
    idle: [40],
    dropped: [45, 46, 47, 41, 40, 40],
    carry: [42, 43, 44],
    fly: [45],
    explode: [45, 46, 47, 41],
    sink: [249, 250, 251],
    ghost: [311]
  },
  beczki: {
    idle: [101],
    dropped: [101],
    carry: [48, 49, 50],
    fly: [51],
    explode: [51, 52, 53, 53],
    sink: [243, 244, 245],
    ghost: [311]
  },
  krzesla: {
    idle: [111],
    dropped: [111],
    carry: [54, 55, 56],
    fly: [57],
    explode: [57, 58, 59, 59],
    sink: [246, 247, 248],
    ghost: [311]
  },
  hero0: getAnimations(commonSpriteSheet),
  hero1: getAnimations(commonSpriteSheet)
};

export const commonAnimatorConfig = {
  cols: 504 / 42,
  rows: 1092 / 42,
  animationSpeed: Constants.ANIM_SPEED
};

export const thunderConfig = {
  animations: animatorConfig.thunder,
  tilewidth: 42,
  tileheight: 126,
  width: 252,
  height: 126,
  cols: 252 / 42,
  rows: 1
};

export const beardPositions: Record<string, Vector> = {
  '0': { x: 17, y: 20 },
  '1': { x: 18, y: 18 },
  '2': { x: 19, y: 20 },
  '4': { x: 17, y: 19 },
  '5': { x: 18, y: 20 },
  '6': { x: 17, y: 19 },
  '7': { x: 18, y: 20 },
  '8': { x: 17, y: 19 },
  '10': { x: 19, y: 20 },
  '11': { x: 20, y: 21 },
  '12': { x: 18, y: 19 },
  '13': { x: 17, y: 20 },
  '14': { x: 17, y: 20 },
  '15': { x: 18, y: 19 },
  '17': { x: 18, y: 19 },
  '18': { x: 16, y: 20 },
  '19': { x: 17, y: 20 },
  '20': { x: 17, y: 19 },
  '24': { x: 18, y: 18 },
  '25': { x: 14, y: 16 },
  '26': { x: 12, y: 16 },
  '28': { x: 12, y: 58 },
  '29': { x: 12, y: 62 },
  '36': { x: 17, y: 19 },
  '37': { x: 18, y: 18 },
  '38': { x: 19, y: 19 },
  offset: { x: 18, y: 18 },
  pivot: { x: 0, y: -12 },
  deadPivot: { x: -41, y: -10 }
};

export enum SpriteSheet {
  HATS = './assets/spritesheet-hats.png',
  FOLK_CONCERT = './assets/spritesheet-folk-concert.png',
  LATRINE_DOMINATION = './assets/spritesheet-latrine-domination.png',
  THUNDER = './assets/spritesheet-thunder.png',
  WHITE = './assets/white.png',
  HEART = './assets/heart.png'
}
