import React, { FC, useMemo } from 'react';

export const Button: FC<{
  name: string;
  type: string;
  current: string;
  setCurrent: (current: string) => void;
  disabled?: boolean;
  className?: string;
}> = ({ name, type, current, setCurrent, disabled, className }) => {
  const classNames = useMemo(() => {
    const classes = ['button-border', `button-${type}`, className].filter(
      Boolean
    );

    if (current === name) {
      classes.push('button-pushed');
    }

    return classes.join(' ');
  }, [name, current]);

  return (
    <button
      disabled={disabled}
      className={classNames}
      tabIndex={0}
      onClick={() => {
        setCurrent(name);
      }}
    >
      {name.toUpperCase()}
    </button>
  );
};
