import * as PIXI from 'pixi.js';

import {
  AnimationFrame,
  Collider,
  JSON,
  LevelObject,
  SceneLevel,
  TScene,
  TeamPoints,
  Tile,
  Toilet
} from '../utils/model';
import { Body, Vector } from 'detect-collisions';
import { CircleBody, GameObject, PolygonBody } from '@pietal.dev/engine';
import { Constants, SpriteSheet, skipWaterTilesGids } from '../utils/constants';
import {
  createBody,
  createGameObjectFromCollider,
  createLabel,
  findTileByGID,
  randomFrom
} from '../utils';
import { takeUntil, throttleTime } from 'rxjs';

import { EllipseBody } from './ellipse-body';
import { Item } from '../prefabs/item.prefab';
import { Player } from '../prefabs/player.prefab';
import { TiledUtils } from './tiled-utils';
import commonSpriteSheet from '../assets/spritesheet-common.json';
import { createBush } from '../prefabs/bush.prefab';
import detect from '../utils/detect';
import level1 from '../assets/map-folk-concert.json';
import level2 from '../assets/map-latrine-domination.json';

export const levels = [level1, level2];

const { tileWidth, tileHeight } = commonSpriteSheet;

export class Level implements SceneLevel {
  width: number;
  height: number;
  walls = new GameObject('walls');
  water = new GameObject('water');
  toilets: Toilet[] = [];
  waterTiles: Record<string, boolean>;
  teamPoints: TeamPoints = [0, 0];
  startedAt = Date.now();
  lastTeam = 0;
  timeout: number;
  mapIndex: number;
  animatedTiles: Tile[];
  tileColliders: Collider[];
  objects: LevelObject[];
  groups: Record<string, Collider[]>;
  affinities = [
    [0, 0],
    [0, 0]
  ];

  constructor(scene: TScene) {
    const levelData = levels[scene.mapIndex];
    const levelTiles = this.getTiles(levelData);
    const firstgid = levelData.tilesets[0].firstgid || 0;

    this.animatedTiles = this.getAnimatedTiles(levelTiles, firstgid);
    this.tileColliders = this.getTileColliders(levelTiles, firstgid);

    this.width = levelData.width * tileWidth;
    this.height = levelData.height * tileHeight;

    this.water.x = this.width;
    this.water.y = this.height;
    this.waterTiles = this.getWaterTiles(levelData);

    this.mapIndex = scene.mapIndex;
    this.objects = TiledUtils.getInstance().createObjects(levelData);
    this.groups = this.groupObjects(this.objects);

    if (detect.isFrontend) {
      TiledUtils.getInstance().setTexturePath(
        levelData.tilesets[0].image as SpriteSheet
      );

      this.addObjectGroups(scene, levelData);
    }

    this.addTileLayers(scene, levelData);
    this.addObjects(scene);

    const bodies = [
      ...this.walls.children,
      ...this.water.children
    ] as unknown[] as Body[];

    bodies.forEach((body) => {
      scene.physics.insert(body);
    });
  }

  getWaterTiles(levelData: JSON): Record<string, boolean> {
    const waterTiles = {};
    const { data } = levelData.layers.find(
      ({ name }: JSON) => name === 'teren'
    );

    if (data) {
      for (let y = 0; y < levelData.height; y++) {
        for (let x = 0; x < levelData.width; x++) {
          const gid = data[x + y * levelData.width];

          waterTiles[`${x}:${y}`] = skipWaterTilesGids.includes(gid);
        }
      }
    }

    return waterTiles;
  }

  getNextSpawn(team = 0): Vector {
    const group = this.mapIndex ? `spawn_${1 + (team % 2)}` : 'spawny';
    const spawns = this.groups[group];
    const { x, y } = randomFrom(spawns);

    return { x, y };
  }

  getAnimationFrames(gid: number): AnimationFrame[] | undefined {
    return this.animatedTiles.find(findTileByGID(gid))?.animation;
  }

  getCollider(gid: number): Collider | undefined {
    return this.tileColliders.find(findTileByGID(gid));
  }

  getTiles(levelData: JSON): [number, Tile][] {
    const { tiles, firstgid } = levelData.tilesets[0] as {
      tiles: Tile[] | Record<string, Tile>;
      firstgid: number;
    };

    const mapped = (
      Array.isArray(tiles)
        ? tiles.map((tile: Tile) => [tile.id, tile])
        : Object.entries(tiles).map(([gid, tile]) => [Number(gid), tile])
    ) as [number, Tile][];

    return mapped.filter(
      ([gid]) => gid && !skipWaterTilesGids.includes(gid + firstgid)
    );
  }

  getAnimatedTiles(tiles: [number, Tile][], firstgid: number): Tile[] {
    return tiles
      .filter(([, value]) => value?.animation)
      .map(([gid, value]) => ({
        ...value,
        gid: gid + firstgid
      }));
  }

  getTileColliders(tiles: [number, Tile][], firstgid: number): Collider[] {
    return tiles
      .filter(([, value]) => {
        const object = value?.objectgroup?.objects?.[0];

        return object?.polygon || object?.ellipse;
      })
      .map(([gid, value]) => {
        const object = value.objectgroup.objects[0];
        const offsetX = object.ellipse ? object.width / 2 : 0;
        const offsetY = object.ellipse ? object.height / 2 : 0;
        const name = object.name || 'water';
        const water = name === 'water';
        const type = water ? 'water' : 'wall';

        return {
          ...object,
          x: object.x + offsetX,
          y: object.y + offsetY,
          name,
          type,
          gid: gid + firstgid
        };
      });
  }

  getColliderFromObjects(objects: Collider[]): Collider | null {
    const found = objects.find(({ label }) =>
      label.match(/collider_wall|wokal|domekbok|kibel_\d$/i)
    );

    if (found) {
      // eslint-disable-next-line
      const { group, type, ...collider } = found;

      return collider;
    }

    return null;
  }

  addTileLayers(scene: TScene, levelData: JSON): void {
    const tiledUtils = TiledUtils.getInstance();

    levelData.layers.forEach((layer: JSON, index: number) => {
      if (layer.type === 'tilelayer') {
        const tileLayer = tiledUtils.createTileLayer(layer, this);

        scene.stage.addChildAt(tileLayer, index);
      }
    });
  }

  addObjects(scene: TScene): void {
    Object.entries(this.groups).forEach(([group, objects]) => {
      if (group.match(/beczki|krzesla|baby|chicken|krzaki/)) {
        // eslint-disable-next-line
        objects.forEach(({ x, y, width, height, id: _remove, ...collider }) => {
          const targetX = x + 0.5 * width;
          const targetY = y - 0.5 * height;
          const source = {
            ...collider,
            width,
            height,
            group,
            x: targetX,
            y: targetY
          };

          if (collider.label.match(/collider/)) {
            createBody(scene.bushes, source, {
              isTrigger: true,
              isStatic: true
            });
          } else if (group.match(/krzaki/)) {
            if (detect.isFrontend) {
              const bush = createBush(source);

              scene.stage.addChild(bush);
            }
          } else {
            const item = new Item(scene, source);
            const target = group.match(/baby/) ? scene.baby : scene.items;

            target.push(item);
          }
        });
      } else {
        /* crucial else */
        const collider = this.getColliderFromObjects(objects);
        if (!collider) {
          return;
        }

        const gameObject = createGameObjectFromCollider(collider, objects);
        if (!gameObject) {
          return;
        }

        if (group.match(/kibel/)) {
          const toilet = gameObject as Toilet;

          toilet.range = this.createToiletRange(toilet, scene);
          this.toilets.push(toilet);

          if (detect.isFrontend) {
            toilet.update$
              .pipe(
                takeUntil(toilet.destroy$),
                throttleTime(Constants.THROTTLE_UPDATE)
              )
              .subscribe(() => {
                this.updateToiletVisibility(toilet);
              });
          }
        }

        if (group.match(/pu$/)) {
          scene.powerups.push(gameObject);

          // if (group.match(/bees/)) {
          //   gameObject.particles = await createParticles(gameObject, ["beesPU"]);
          //   gameObject.update$
          //     .pipe(takeUntil(gameObject.destroy$))
          //     .subscribe(() => {
          //       updateParticles(gameObject)
          //     });
          // } else {
          //   const graph = gameObject.sprite.children.find(
          //     ({ label }) => !label.match(/graph/)
          //   );
          //   const startX = graph.x;
          //   const startY = graph.y;
          //   gameObject.update$
          //     .pipe(
          //       takeUntil(gameObject.destroy$),
          //       throttleTime(Constants.FADEOUT_TIMEOUT),
          //       map(() => gameObject.isTaken)
          //     )
          //     .subscribe((isTaken: boolean) => {
          //       powerupFadeout(graph, isTaken, startX, startY)
          //     });
          // }
        }

        if (group === 'wokal') {
          gameObject.destroy();
        } else {
          scene.addChild(gameObject);

          if (detect.isFrontend) {
            scene.stage.addChild(gameObject.sprite);
          }
        }
      }
    });

    scene.bushes.children.forEach((bushBody) => {
      scene.physics.insert(bushBody as EllipseBody | PolygonBody);
    });

    scene.addChild(scene.bushes);
  }

  addObjectGroups(scene: TScene, levelData: JSON): void {
    levelData.layers
      .filter(({ type }) => type === 'objectgroup')
      .forEach(({ objects, name }: JSON) => {
        objects.forEach(({ x, y, gid }: Collider) => {
          const animationFrames = this.getAnimationFrames(gid);

          if (animationFrames) {
            this.addAnimation(name, scene, { x, y }, animationFrames);
          }
        });
      });
  }

  addAnimation(
    name: string,
    scene: TScene,
    { x, y }: Vector,
    animationFrames?: AnimationFrame[]
  ): PIXI.AnimatedSprite | null {
    if (!detect.isFrontend || !animationFrames) {
      return null;
    }

    const animations = animationFrames.map(({ tileid }) => ({
      texture: TiledUtils.getInstance().getFrame(tileid),
      time: Constants.ANIM_SPEED
    }));

    const animation = new PIXI.AnimatedSprite(animations, true);
    animation.position.set(x, y);
    animation.label = createLabel(name, { x, y });
    animation.zIndex = y + Constants.ANIMATION_ZINDEX * tileHeight;
    animation.anchor.set(0, Constants.ANIMATION_ANCHOR_Y);
    animation.play();

    scene.stage.addChild(animation);

    return animation;
  }

  createToiletRange(toilet: Toilet, { physics }: TScene): CircleBody {
    const range = new CircleBody(
      toilet,
      Constants.RANGE_SIZE,
      Constants.RANGE_SIZE,
      undefined,
      {
        isStatic: true,
        isTrigger: true
      }
    );

    range.label = `${toilet.label}_range`;
    physics.insert(range);

    return range;
  }

  updateToiletVisibility({ sprite, affinity }: Toilet): void {
    (sprite.children as PIXI.AnimatedSprite[])
      .filter(({ label }) => label.match(/(red|blue)/))
      .forEach((child) => {
        child.visible = !!affinity && child.label.includes(affinity);
      });
  }

  isOnWater({ x, y }: Vector): boolean {
    const position = `${Math.floor(x / tileWidth)}:${Math.floor(y / tileHeight)}`;

    return (
      x >= this.width ||
      y >= this.height ||
      x < 0 ||
      y < 0 ||
      this.waterTiles[position]
    );
  }

  setWin(scene: TScene): void {
    const [redTeamPoints, blueTeamPoints] = this.teamPoints || [0, 0];

    if (scene.player) {
      scene.pushEvent?.(
        scene.player,
        'ctfWin',
        redTeamPoints === blueTeamPoints
          ? 0
          : redTeamPoints > blueTeamPoints
            ? 1
            : -1
      );
    }
  }

  restart(): void {
    this.startedAt = Date.now();
    this.teamPoints = [0, 0];
    this.toilets.forEach((toilet) => {
      toilet.taken = 0;
      toilet.affinity = null;
    });
  }

  respawn(player: Player): void {
    const { x, y } = this.getNextSpawn(player.team);

    player.respawn({ x, y });
  }

  groupObjects(objects: LevelObject[]): Record<string, Collider[]> {
    return objects.reduce(
      (json, object) => {
        const group = (object.group || object.label).toLowerCase();
        const collider = { ...object, group } as Collider;

        return {
          ...json,
          [group]: json[group] ? [...json[group], collider] : [collider]
        };
      },
      {} as Record<string, Collider[]>
    );
  }
}
