import { Scene } from '@pietal.dev/engine';

// can't use MyScene.getQueryParams() here yet (!)
const isServerTest = 'server' in Scene.getQueryParams();
const isServer = false;
const isFrontend = !isServerTest && !isServer;

export default {
  isServer,
  isServerTest,
  isFrontend
};
