import * as PIXI from 'pixi.js';

import {
  AIConsumer,
  Collider,
  FlipX,
  HasClock,
  HasGID,
  JSON as JSONType,
  MyGameObject,
  Skill
} from './model';
import { BodyProps, Vector, mapArrayToVector } from 'detect-collisions';
import {
  CircleBody,
  Container,
  GameObject,
  PolygonBody,
  TGameObject
} from '@pietal.dev/engine';
import { Constants, Skills, runStates, walkStates } from './constants';

import { EllipseBody } from '../classes/ellipse-body';
import { FixSprite } from '../classes/fix-sprite';
import { Player } from '../prefabs/player.prefab';
import { TiledUtils } from '../classes/tiled-utils';
import commonSpriteSheet from '../assets/spritesheet-common.json';
import detect from './detect';

const { tileWidth, tileHeight } = commonSpriteSheet;

export function getIdFromVector({ x, y }: Vector): string {
  return `${Math.round(x / tileWidth)}:${Math.round(y / tileHeight)}`;
}

export function getTargetBeardRotation(player: Player): number {
  return (
    Math.sign(player.sprite.scale.x) *
    Math.cos(Date.now() / Constants.BEARD_ROTATION_DELAY) *
    Constants.BEARD_ROTATION_DAMP *
    player.beardLength
  );
}

export function getFlipX(gameObject: AIConsumer): FlipX {
  const {
    sprite,
    body: { angle }
  } = gameObject;

  const flipX = isNaN(angle) ? sprite?.x || 1 : Math.sign(Math.cos(angle));

  return (
    Player.hasHitState(gameObject) || Player.hasDeadState(gameObject)
      ? -flipX
      : flipX
  ) as FlipX;
}

export function getPointsFromCollider({
  width,
  height
}: Partial<Collider>): number[][] {
  return [
    [0, -height],
    [width, -height],
    [width, 0],
    [0, 0]
  ];
}

export function setTarget(consumer: AIConsumer, target?: Vector): void {
  if (!target) {
    return;
  }

  const { x, y } = target;
  const angle = Math.atan2(y - consumer.y, x - consumer.x);
  const maxSq = Math.pow(Constants.MAX_TARGET_DISTANCE, 2);
  const distance = Math.min(maxSq, distanceSq(consumer, { x, y }));

  if (canMove(consumer)) {
    consumer.target = { x, y, distance, angle };
  }
  if (canRun(consumer)) {
    consumer.state = 'run'; // safe
  }
}

export function createLabel(label: string, { x, y }: Vector): string {
  return `${label.toLowerCase()}-${getIdFromVector({ x, y })}`;
}

export function createBody(
  gameObject: GameObject,
  { label, x, y, width, height, ellipse, polygon }: Omit<Collider, 'id'>,
  bodyOptions?: Partial<BodyProps>
): CircleBody | PolygonBody | null {
  if (ellipse) {
    const radius: number = (width + height) / 5;

    return new EllipseBody(gameObject, radius, bodyOptions);
  }

  if (polygon) {
    const points = polygon.map((point: Vector) => ({
      x: point.x + x,
      y: point.y + y
    }));

    return new PolygonBody(gameObject, points, bodyOptions);
  }

  if (!label.match(/kapela|domekbok/)) {
    const points = getPointsFromCollider({ width, height }).map(
      mapArrayToVector
    );

    return new PolygonBody(gameObject, points, bodyOptions);
  }

  return null;
}

export function createGraphics(
  { gid, x, y, group }: Partial<Collider>,
  firstgid = 1
): PIXI.Sprite | null {
  if (!gid) {
    console.error('missing gid');

    return null;
  }

  if (!group) {
    console.error('missing group');

    return null;
  }

  const texture = TiledUtils.getInstance().getFrame(gid, firstgid);
  const sprite = new FixSprite(texture);

  sprite.anchor.set(0, 1);
  sprite.position.set(x, y);
  sprite.label = createLabel(group, sprite);
  sprite.zIndex = y;

  return sprite;
}

export function createSprite(
  gameObject: GameObject,
  colliders: Collider[]
): Container {
  const container = new Container(gameObject);
  container.label = createLabel(gameObject.label, gameObject);
  container.zIndex = gameObject.y;
  container.sortableChildren = false;

  colliders.forEach(({ gid, label: group, ...position }) => {
    const x = position.x - gameObject.x;
    const y = position.y - gameObject.y;
    const sprite = createGraphics({ x, y, gid, group });

    if (sprite) {
      container.addChild(sprite);
    }
  });

  return container;
}

export function createGameObject(
  collider: Collider,
  objects: Collider[]
): MyGameObject {
  const tiles = objects.filter(({ label }) => !label.includes('collider'));
  const gameObject = new GameObject(
    collider.label.toLowerCase(),
    collider.x,
    collider.y
  ) as MyGameObject;

  gameObject.body = createBody(gameObject, collider, { isStatic: true });

  if (detect.isFrontend) {
    gameObject.sprite = createSprite(gameObject, tiles);
  }

  return gameObject;
}

export function createGameObjectFromCollider(
  { x, y, ...collider }: Collider,
  objects: Collider[]
): MyGameObject | null {
  if (!collider) {
    console.error('missing collider');

    return null;
  }

  let offsetX = 0;
  let offsetY = -Constants.COLLIDER_OFFSET_Y;

  if (collider.ellipse) {
    offsetX = collider.width / 2;
    offsetY = collider.height / 2;
  } else if (!collider.label.match(/wokal|domekbok/)) {
    offsetY = collider.height;
  }

  const gameObject = createGameObject(
    { ...collider, x: x + offsetX, y: y + offsetY },
    objects
  );

  if (gameObject.label.match(/scena/) && gameObject.sprite) {
    gameObject.sprite.zIndex -= tileHeight;
  }

  return gameObject;
}

export function destroyGameObject(gameObject: TGameObject & HasClock): void {
  // clear possible timeout
  clearTimeout(gameObject.timeout);

  if (gameObject.body) {
    const scene = gameObject.scene;
    scene?.physics.remove(gameObject.body);

    gameObject.body.destroy();
    gameObject.body = null;
  }

  if (gameObject.sprite) {
    gameObject.sprite.destroy();
    gameObject.sprite = null;
  }

  gameObject.destroy();
}

export function canRun({ state }: Pick<AIConsumer, 'state'>): boolean {
  return runStates.includes(state);
}

export function canWalk({ state }: Pick<AIConsumer, 'state'>): boolean {
  return walkStates.includes(state);
}

export function canMove({ state }: Pick<AIConsumer, 'state'>): boolean {
  return canRun({ state }) || canWalk({ state });
}

export function distanceSort(from: Vector): (a: Vector, b: Vector) => 1 | -1 {
  return (a: Vector, b: Vector) =>
    distanceSq(from, a) < distanceSq(from, b) ? -1 : 1;
}

export function distanceSq(a: Vector, b: Vector): number {
  return (a.x - b.x) * (a.x - b.x) + (a.y - b.y) * (a.y - b.y);
}

export function randomId(): string {
  return Math.random().toString(36).substr(2);
}

export function cloneJSON(json: JSONType): JSONType {
  return JSON.parse(JSON.stringify(json));
}

export function findTileByGID(gid: number): (test: HasGID) => boolean {
  return ({ gid: compare }) => compare === gid;
}

export function canShowThunder(player: Player): boolean {
  const longPrep =
    (Date.now() - player.prepareSince) / Constants.PUNCH_ONE_LAYER >= 3;

  return (
    player.state === 'prep' &&
    (player.isThor || (longPrep && canUseSkill(player, Skills.POKE_OF_ODIN)))
  );
}

export function canUseSkill(player: Player, skillIndex: Skills): boolean {
  const skill = player.skills[skillIndex];

  if (!skillReady(skill) || player.beardLength / 5 < skillIndex) {
    return false;
  }

  return true;
}

export function skillReady(skill: Skill): boolean {
  if (!skill.cooldown) {
    return true;
  }

  return Date.now() >= skill.cooldown;
}

export function lerp(a: number, b: number, c: number): number {
  const safe = Math.max(0, Math.min(1, c));

  return a * (1 - safe) + b * safe;
}

export function randomFrom<T = string>(
  array: T[] = [],
  random = Math.random
): T {
  return array[Math.floor(random() * array.length)];
}
