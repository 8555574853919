import { TScene, WSProps } from '../utils/model';

import { MyScene } from './my-scene';
import { Player } from '../prefabs/player.prefab';
import { api } from '../utils/api';
import { io } from 'socket.io-client';

export class SocketClient {
  started = false;
  label = '';
  socketio: WSProps;

  constructor(scene: TScene) {
    // eslint-disable-next-line
    const onDisconnect = (error: any) => {
      console.log('offline', error.message || error);
      api.physics = true;

      MyScene.start(scene);
    };

    this.socketio = io() as WSProps;

    this.socketio.on('connect_error', onDisconnect);

    this.socketio.on('disconnect', onDisconnect);

    this.socketio.on('/start', (id) => {
      if (!this.started) {
        this.started = true;

        this.label = id;
        this.socketio.on('/json', (json) => {
          scene.onJSON(json);
        });

        console.log('online', id);
        api.physics = false;

        MyScene.start(scene);
      }
    });
  }

  login(mapIndex: number, player: Player) {
    this.socketio.emit('/join', 'vikings');
    this.socketio.emit('/id', {
      id: player.id,
      team: Number(MyScene.getQueryParams().team || player.team),
      hatIndex: Number(MyScene.getQueryParams().hat || player.hatIndex),
      beardLength: Number(MyScene.getQueryParams().beard || player.beardLength),
      mapIndex: mapIndex
    });
  }
}
