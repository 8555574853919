import * as PIXI from 'pixi.js';

import { Constants, SpriteSheet } from '../utils/constants';

import { Container } from '@pietal.dev/engine';
import { FixSprite } from '../classes/fix-sprite';
import { Player } from './player.prefab';
import { deg2rad } from 'detect-collisions';
import { lerp } from '../utils';
import { textures } from '../utils/texture-cache';

export class InfoContainer extends Container {
  _text: PIXI.Text;
  value: string;
  hearts: PIXI.Sprite[] = [];
  isMainPlayer: boolean;

  set text(text: string) {
    if (text === this.value) {
      return;
    }
    this.value = text;
    this._text.text = text;
  }

  constructor(player: Player) {
    super(player);

    try {
      this.createText();
    } catch (error: unknown) {
      console.warn(error);
    }

    try {
      this.createHearts();
    } catch (error: unknown) {
      console.warn(error);
    }
  }

  update(deltaTime: number) {
    super.update(deltaTime);

    const player = this.gameObject as Player;
    const fade = Constants.HEART_FADE * deltaTime;
    this.zIndex = player.sprite.zIndex;

    const changeTint = this.mainPlayerChanged();
    this.isMainPlayer = Player.isMainPlayer(player);

    this.hearts.forEach((heart, index) => {
      const show = index < player.hp;
      const alpha = show ? 1 : 0;

      heart.alpha = lerp(heart.alpha, alpha, fade);
      heart.y = Constants.HEART_OFFSET_Y - 2 * (1 - heart.alpha) * heart.height;

      if (show) {
        const positionX = index * heart.width;
        const centerX = heart.width * (player.hp / 2);

        heart.x = positionX - centerX;
      }

      if (changeTint) {
        heart.tint = this.getTint();
      }

      heart.visible = heart.alpha > 0;
    });

    if (changeTint) {
      this.updateTextTint();
    }
  }

  hideHearts(): void {
    this.hearts.forEach((heart) => {
      heart.alpha = 0;
    });
  }

  protected mainPlayerChanged(): boolean {
    const isMainPlayer = Player.isMainPlayer(this.gameObject as Player);

    return this.isMainPlayer !== isMainPlayer;
  }

  protected updateTextTint(): void {
    this._text.tint = this.getTint();
  }

  protected getTint(): number {
    const player = this.gameObject as Player;
    if (Player.isMainPlayer(player)) {
      return Constants.PLAYER_TINT;
    }

    return Player.isBlueTeam(player)
      ? Constants.TEAM_BLUE_TINT
      : Constants.TEAM_RED_TINT;
  }

  protected createHearts(): void {
    const player = this.gameObject as Player;
    for (let index = 0; index < player.maxHP; index++) {
      const heart = new FixSprite(textures[SpriteSheet.HEART]);
      const centerX = (player.hp * heart.width) / 2;

      heart.x = index * heart.width - centerX;
      heart.y = Constants.HEART_OFFSET_Y;
      heart.anchor.set(0, 0);
      heart.texture.source.scaleMode = 'nearest';
      heart.alpha = index < player.hp ? 1 : 0;
      heart.tint = this.getTint();

      this.hearts.push(heart);
      this.addChild(heart);
    }
  }

  protected createText(): void {
    const player = this.gameObject as Player;
    const scale = 0.5 / Constants.MIN_SCALE;
    const fontSize = Math.round(Constants.NAME_FONT_SIZE / scale);
    const texture = textures[SpriteSheet.WHITE];

    const style: Partial<PIXI.TextStyleOptions> = new PIXI.TextStyle({
      fontFamily: "'monogram'",
      fontSize,
      fill: { texture },
      dropShadow: {
        color: 0x383838,
        blur: 0,
        alpha: 0.7,
        angle: deg2rad(45),
        distance: 2 * Constants.MIN_SCALE
      }
    });

    this._text = new PIXI.Text({
      text: player.id,
      tint: this.getTint(),
      style,
      position: { x: 0, y: Constants.NAME_OFFSET_Y },
      anchor: { x: 0.5, y: 1 },
      scale: { x: scale, y: scale }
    });

    this.addChild(this._text);
  }
}
