import * as PIXI from 'pixi.js';

import { Collider } from '../utils/model';
import { createGraphics } from '../utils';

export function createBush({
  gid,
  x,
  y,
  height,
  group
}: Partial<Collider>): PIXI.Sprite | null {
  const bush = createGraphics({ gid, group, x, y });
  if (!bush) {
    console.error("can't create bush");

    return null;
  }

  bush.y += height / 2;
  bush.zIndex = bush.y;
  bush.anchor.set(0.5, 1);

  return bush;
}
