import * as PIXI from 'pixi.js';

import {
  AIConsumer,
  Binary,
  BossProps,
  Button,
  CommonJSONEncode,
  JSON,
  JSONData,
  JSONObjectParsed,
  Mouse,
  ScenePowerup,
  TBody,
  TScene,
  ToiletAffinity,
  WorldExtraData
} from '../utils/model';
import { API, api } from '../utils/api';
import { GameObject, Scene, SceneSSR } from '@pietal.dev/engine';
import { Response, Vector } from 'detect-collisions';
import {
  canMove,
  destroyGameObject,
  lerp,
  randomFrom,
  randomId
} from '../utils';
import {
  encodeBoolean,
  encodeItem,
  encodePlayer,
  encodeWorldExtra
} from '../utils/encode';
import { fromEvent, takeUntil, tap, throttleTime } from 'rxjs';

import { Constants } from '../utils/constants';
import { EllipseBody } from './ellipse-body';
import { Item } from '../prefabs/item.prefab';
import { Level } from './level';
import { Player } from '../prefabs/player.prefab';
import { SoundPlayer } from './sound-player';
import detect from '../utils/detect';
import { getMouse } from '../utils/mouse';
import { prepareCache } from '../utils/texture-cache';

export class MyScene extends Scene implements TScene {
  mouse: Mouse = { x: 0, y: 0, angle: 0, distance: 0 };
  lastJSON: number = -Infinity;
  playerNames: string[] = [];
  name: string;
  powerups: ScenePowerup[];
  bushes: GameObject;
  players: Player[];
  items: Item[];
  baby: Item[];
  level: Level;
  mapIndex: number;
  zoom: number;
  started = false;
  api?: API;
  boss?: BossProps;
  player?: Player;

  static queryParams?: Record<string, string>;

  static hasPhysics(): boolean {
    return detect.isServer || api.physics || false;
  }

  static getQueryParams(): Record<string, string> {
    if (!MyScene.queryParams) {
      MyScene.queryParams = Scene.getQueryParams();
    }

    return MyScene.queryParams;
  }

  static getTargetFromMouse(mouse: Mouse, player: Player): Mouse | null {
    if (!mouse) {
      return null;
    }

    const { x, y, angle, distance: mouseDistance } = mouse;
    const distance = Math.min(mouseDistance, Constants.MAX_TARGET_DISTANCE);

    if (distance < Constants.MIN_PX_IDLE_TO_RUN) {
      return null;
    }

    return {
      x: player.x + x * distance,
      y: player.y + y * distance,
      distance,
      angle
    };
  }

  static toJSON(scene: TScene): JSONData {
    return {
      now: Date.now(),
      boss: scene.boss ? encodePlayer(scene.boss) : [],
      players: scene.players.map(encodePlayer),
      baby: scene.baby.map(encodeItem),
      items: scene.items.map(encodeItem),
      pu: scene.powerups.map(({ isTaken }) => encodeBoolean(isTaken)),
      extra: scene.mapIndex ? encodeWorldExtra(scene) : []
    };
  }

  static onSeparateBody({
    a: body1,
    b: body2,
    overlapV: { x, y }
  }: Response): boolean {
    if (body2.isTrigger) {
      return false;
    }

    const water = body2.gameObject.label === 'water';
    const item1: Item = body1.gameObject instanceof Item && body1.gameObject;
    const item2: Item = body2.gameObject instanceof Item && body2.gameObject;
    const player1: Player =
      body1.gameObject instanceof Player && body1.gameObject;
    const player2: Player =
      body2.gameObject instanceof Player && body2.gameObject;

    if (water) {
      return true;
    }

    if (MyScene.hasPhysics()) {
      if (player1) {
        player1.onCollidePlayer(player2);
        player1.onCollideItem(item2);
      }

      if (item1?.state === 'fly' && item1.owner !== player2) {
        if (player2) {
          player2.damage(item1);
        }

        item1.explode();
      }
    }

    // always, on trigger bodies, push back and return false
    if (item1 && body1.isTrigger && Item.hasPushbackState(item1)) {
      body1.setPosition(body1.x - x, body1.y - y);

      return false;
    }

    return true;
  }

  static separateBody(body: EllipseBody): void {
    body.system?.separateBody(body, MyScene.onSeparateBody);
  }

  static async create(
    mapIndex: number,
    canvas?: HTMLCanvasElement,
    name?: string
  ): Promise<TScene> {
    const scene = (
      detect.isFrontend || detect.isServerTest
        ? await MyScene.createFrontendScene(canvas)
        : MyScene.createBackendScene()
    ) as TScene;

    scene.name = randomId();
    scene.mapIndex = mapIndex;
    scene.powerups = [];
    scene.items = [];
    scene.baby = [];
    scene.bushes = new GameObject('bushes');
    scene.level = new Level(scene);
    scene.players = MyScene.createPlayers(scene);
    scene.api = api;

    if (scene.boss) {
      scene.boss.delayedJumpOff();
    }

    if (detect.isFrontend || detect.isServerTest) {
      scene.onResize();
      scene.centerCamera();

      const player = randomFrom(scene.players);
      const params = { ...MyScene.getQueryParams(), name };
      scene.initMainPlayer(player, params, true);
    }

    return scene;
  }

  static start(scene: TScene): void {
    if (scene.started) {
      return;
    }

    if (scene.pixi) {
      scene.pixi.stage.alpha = 0;
    }

    scene.update$
      .pipe(
        takeUntil(scene.destroy$),
        tap((deltaTime) => {
          MyScene.updatePhysics(scene);

          if (scene instanceof MyScene) {
            scene.updateCamera(deltaTime);

            scene.pixiStage.alpha = lerp(
              scene.pixiStage.alpha,
              1,
              deltaTime * Constants.ALPHA_FADE
            );
          }
        }),
        throttleTime(Constants.THROTTLE_UPDATE)
      )
      .subscribe(() => {
        MyScene.updateBehindBush(scene);

        if (scene.mapIndex) {
          MyScene.updateToilets(scene);
        }
      });

    scene.destroy$.subscribe(() => {
      MyScene.onSceneDestroy(scene);
    });

    scene.start(); // safe

    scene.started = true;
  }

  static getTargetFromAngle(
    angle: number,
    distance = Constants.PUSH_BACK_RANGE
  ): Mouse {
    return {
      x: Math.cos(angle),
      y: Math.cos(angle),
      distance,
      angle
    };
  }

  protected static collisionHandlerForBush({
    a: { gameObject },
    b: { gameObject: collider }
  }: Response): void {
    if (collider.label === 'bushes') {
      gameObject.isBehindBush = true;
    }
  }

  protected static updateBehindBush({
    items,
    baby,
    players,
    physics
  }: TScene): void {
    const moving = [...items, ...baby, ...players];
    moving.forEach((gameObject) => {
      gameObject.isBehindBush = false;
    });

    moving.forEach(({ body }) => {
      physics.checkOne(body, MyScene.collisionHandlerForBush);
    });
  }

  protected static onSceneDestroy(scene: TScene): void {
    const bodies: TBody[] = scene.physics.all();

    bodies.forEach((body) => {
      if (body.gameObject) {
        destroyGameObject(body.gameObject);
      }
      scene.physics.remove(body);
    });

    scene.physics.clear();

    if (scene.player) {
      scene.player.destroy();
      scene.player = null;
    }
  }

  protected static updatePhysics(scene: TScene): void {
    const moving = [...scene.items, ...scene.baby, ...scene.players];

    moving.map(({ body }) => body).forEach(MyScene.separateBody);
  }

  protected static createPlayers(scene: TScene): Player[] {
    return Array.from(
      {
        length: scene.mapIndex
          ? Constants.MAP_2_TEAM_PLAYERS * 2
          : Constants.MAP_1_PLAYERS
      },
      () => new Player(scene)
    );
  }

  protected static async createFrontendScene(
    canvas?: HTMLCanvasElement
  ): Promise<MyScene> {
    await prepareCache();

    const scene = new MyScene();
    await scene.init({ canvas });

    setTimeout(() => {
      SoundPlayer.prepareSounds();
    });

    return scene;
  }

  protected static createBackendScene(): SceneSSR {
    return new SceneSSR({ visible: true });
  }

  protected static updateToilets({
    players,
    physics,
    level: { toilets, teamPoints, affinities }
  }: TScene): void {
    toilets.forEach(({ range }, index) => {
      physics.checkOne(range, ({ b: { gameObject } }: Response): void => {
        const affinity = affinities[index];
        const player = gameObject instanceof Player && gameObject;

        if (player) {
          affinity[player.team]++;
        }
      });
    });

    toilets.forEach((toilet, index) => {
      const [red, blue] = affinities[index];
      toilet.affinity =
        red !== blue ? (red > blue ? 'red' : 'blue') : undefined;

      if (toilet.affinity) {
        const team = red > blue ? 0 : 1;
        teamPoints[team]++;
        players
          .filter((player) => player.team === team)
          .forEach((player) => {
            player.score++;
          });
      }
    });
  }

  get pixiStage(): PIXI.Container {
    return this.pixi.stage;
  }

  constructor() {
    const queryParams = MyScene.getQueryParams();
    super({
      visible: true,
      showFPS: 'fps' in queryParams,
      debug: 'debug' in queryParams
    });

    this.zoom = Number(queryParams.zoom || 1);
  }

  async init({ canvas }: { canvas?: HTMLCanvasElement }): Promise<boolean> {
    const ok = await super.init({
      resizeTo: canvas.parentElement,
      eventMode: 'none',
      autoStart: false,
      autoDensity: true,
      sharedTicker: true,
      backgroundAlpha: 1,
      backgroundColor: 0x4ea2b7,
      clearBeforeRender: false,
      canvas
    });

    if (ok) {
      this.stage.sortableChildren = true;

      setTimeout(() => {
        this.bindEvents();
        this.bindPlayerEvents();
      });
    }

    return ok;
  }

  // @TODO sm:2 md:3 lg:4
  getScale(): number {
    const diagonal = Math.hypot(innerWidth, innerHeight);

    return diagonal * Constants.CAMERA_ZOOM;
  }

  // emits event to backend
  emitEvent(player: Player, { event, data }: JSON = {}): boolean {
    if (!player.isHuman) {
      return false;
    }

    if (MyScene.hasPhysics()) {
      return false;
    }

    api.client.socketio.emit(event, data);
    return true;
  }

  centerCamera(): void {
    const stageCenter = this.getStageCenter();
    const { position } = this.pixiStage;

    position.set(stageCenter.x, stageCenter.y);
    if (this.pixi.renderer) {
      this.pixi.render();
    }
  }

  initMainPlayer(
    player: Player,
    queryParams = MyScene.getQueryParams(),
    initial = false
  ): void {
    if (this.player === player) {
      return;
    }

    this.players.forEach((cleanup) => {
      cleanup.isHuman = false;
    });

    if (player) {
      this.player = player;
      this.player.isHuman = true;

      if (initial) {
        if (queryParams.name) {
          player.id = queryParams.name;
          player.info.text = queryParams.name;
        }

        player.setHat(Number(queryParams.hat ?? Player.getHatIndex()));
        player.setBeard(Number(queryParams.beard || 0));
      }
    }
  }

  onResize(): void {
    const scale = this.zoom * Math.max(Constants.MIN_SCALE, this.getScale());

    this.pixiStage.scale.set(scale);
    console.log('scale:', parseFloat(scale.toFixed(2)));
  }

  onJSON(jsonData: JSONData): void {
    if (this.lastJSON > jsonData.now) {
      return;
    }

    this.lastJSON = jsonData.now;

    const now = Date.now();
    const keysForUpdate = ['items', 'baby', 'players'];

    this.onExtra(jsonData.extra);
    keysForUpdate.forEach((key) => {
      const jsonObjects: CommonJSONEncode[] = jsonData[key];
      const sceneObjects: AIConsumer[] = this[key];

      jsonObjects.forEach((jsonObject: CommonJSONEncode) => {
        const { id, x, y, angle, distance, state, rest } =
          this.parseJSON(jsonObject);
        const sceneObjectIndex = sceneObjects.findIndex(
          (test) => test?.id === id
        );
        const sceneObject = sceneObjects[sceneObjectIndex];

        if (key === 'players') {
          const [team, beardLength, hatIndex] = rest as [
            Binary,
            number,
            number
          ];

          if (
            !sceneObject ||
            (sceneObject instanceof Player && sceneObject.team !== team)
          ) {
            // @TODO GC()
            sceneObject?.destroy();
            if (sceneObjectIndex !== -1) {
              sceneObjects.splice(sceneObjectIndex, 1);
            }

            const player = new Player(this, {
              id,
              team,
              beardLength,
              hatIndex
            });

            sceneObjects.push(player);
          }
        }

        if (['items', 'baby'].includes(key)) {
          const [group] = rest as string[];

          if (
            !sceneObject ||
            (sceneObject instanceof Item && sceneObject.label !== group)
          ) {
            // @TODO GC()
            sceneObject?.destroy();
            if (sceneObjectIndex !== -1) {
              sceneObjects.splice(sceneObjectIndex, 1);
            }

            const item = new Item(this, { id, x, y, group });

            sceneObjects.push(item);
          }
        }

        const created = sceneObjects.find((test) => test?.id === id);

        // update only players
        if (created && key === 'players') {
          const player = created as Player;
          const [, beardLength, hatIndex, isHuman, hp, pick] = rest as [
            unknown,
            number,
            number,
            number,
            number,
            string
          ];

          player.pick = this.items.find((item) => item.id === pick);
          player.isHuman = !!isHuman;
          player.setHP(hp);
          player.setBeard(beardLength);
          player.setHat(hatIndex);

          if (player.info) {
            player.info.text = id;
          }

          if (id === api.client.label && !Player.isMainPlayer(player)) {
            this.initMainPlayer(player);
          }
        }

        // update universal
        if (created) {
          const target = distance ? ({ distance, angle } as Mouse) : null;

          created.id = id;
          created.body.setPosition(x, y);
          created.state = state;
          created.target = target;
          created.lastUpdate = now;
        }

        // cleanup
        while (sceneObjects.length > jsonObjects.length) {
          const index = sceneObjects.findIndex(
            (test) =>
              // not found
              !jsonObjects.some((jsonObject) => {
                const { id } = this.parseJSON(jsonObject);
                return test.id === id;
              })
          );

          sceneObjects[index].destroy();
          sceneObjects.splice(index, 1);
        }
      });
    });
  }

  protected getPlayerPosition(): Vector {
    if (!this.player) {
      return { x: 0, y: 0 };
    }

    const { scale } = this.pixiStage;
    const cameraCenter = this.getCameraCenter();
    const stageCenter = this.getStageCenter();
    const x = this.player.x * scale.x - stageCenter.x;
    const y = this.player.y * scale.y - stageCenter.y;

    return { x: cameraCenter.x - x, y: cameraCenter.y - y };
  }

  protected getPlayerSpritePosition(): Vector {
    const { sprite } = this.player || {};

    return sprite
      ? { x: sprite.worldTransform.tx, y: sprite.worldTransform.ty }
      : this.player || { x: innerWidth / 2, y: innerHeight / 2 };
  }

  /**
   * not scaled, in screen px
   */
  protected getScreenCenter(): Vector {
    return { x: innerWidth / 2, y: innerHeight / 2 };
  }

  /**
   * scaled, in screen px
   */
  protected getStageCenter(): Vector {
    const { width, height } = this.level;
    const { scale } = this.pixiStage;

    return { x: scale.x * (width / 2), y: scale.y * (height / 2) };
  }

  /**
   * not scaled, in screen px
   */
  protected getCameraCenter(): Vector {
    const screenCenter = this.getScreenCenter();
    const stageCenter = this.getStageCenter();

    return {
      x: screenCenter.x - stageCenter.x,
      y: screenCenter.y - stageCenter.y
    };
  }

  protected updateCamera(deltaTime: number): void {
    if ('pause' in MyScene.getQueryParams()) {
      return;
    }

    const { scale, position } = this.pixiStage;
    const playerPosition = this.getPlayerPosition();
    const distance = Math.hypot(
      scale.x * (playerPosition.x - position.x),
      scale.y * (playerPosition.y - position.y)
    );
    const speed = deltaTime * distance * Constants.CAMERA_SPEED;
    const x = lerp(position.x, playerPosition.x, speed);
    const y = lerp(position.y, playerPosition.y, speed);

    this.clampCamera(x, y);
  }

  protected clampCamera(x: number, y: number): void {
    const cameraCenter = this.getCameraCenter();
    const stageCenter = this.getStageCenter();
    const minX = cameraCenter.x - stageCenter.x;
    const maxX = cameraCenter.x + stageCenter.x;
    const minY = cameraCenter.y - stageCenter.y;
    const maxY = cameraCenter.y + stageCenter.y;
    const { position } = this.pixiStage;

    position.set(
      Math.max(minX, Math.min(x, maxX)),
      Math.max(minY, Math.min(y, maxY))
    );
  }

  protected bindEvents(): void {
    const getPositionFromTouches = (touches: TouchList) => {
      const { x, y } = [...touches].reduce(
        ({ x, y }, touch) => ({
          x: x + touch.pageX,
          y: y + touch.pageY
        }),
        { x: 0, y: 0 }
      );

      return { x: x / touches.length, y: y / touches.length };
    };

    fromEvent(window, 'contextmenu')
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: Event) => {
        event.preventDefault();
      });

    fromEvent(document, 'fullscreenchange', { passive: true })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.onResize();
      });

    fromEvent(window, 'resize', { passive: true })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.onResize();
      });

    fromEvent(window, 'mousedown', { passive: true })
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: PointerEvent) => {
        this.onPointerMove(event.pageX, event.pageY);
      });

    fromEvent(window, 'touchstart', { passive: true })
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ touches }: TouchEvent) => {
        const { x, y } = getPositionFromTouches(touches);
        this.onPointerMove(x, y);
      });

    fromEvent(window, 'mousemove', { passive: true })
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: PointerEvent) => {
        this.onPointerMove(event.pageX, event.pageY);
      });

    fromEvent(window, 'touchmove', { passive: true })
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ touches }: TouchEvent) => {
        const { x, y } = getPositionFromTouches(touches);
        this.onPointerMove(x, y);
      });

    fromEvent(window, 'dblclick', { passive: true })
      .pipe(throttleTime(Constants.FULLSCREEN_CLICK_THROTTLE))
      .subscribe(() => {
        requestAnimationFrame(async () => {
          try {
            await document.body.requestFullscreen();
          } catch (error) {
            console.error('requestFullscreen error', error.message || error);
          }
        });
      });
  }

  protected bindPlayerEvents(): void {
    const tap = (data: Button) => {
      if (!this.emitEvent(this.player, { event: 'tap', data })) {
        this.player.tap(data);
      }
    };

    const untap = () => {
      if (!this.emitEvent(this.player, { event: 'untap' })) {
        this.player.untap();
      }
    };

    fromEvent(window, 'mousedown', { passive: true })
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ button }: PointerEvent) => {
        tap(button as Button);
      });

    fromEvent(window, 'touchstart', { passive: true })
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ touches }: TouchEvent) => {
        if (touches.length > 1) {
          const button = touches.length - 1;
          tap(button as Button);
        }
      });

    fromEvent(window, 'mouseup', { passive: true })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        untap();
      });

    fromEvent(window, 'touchend', { passive: true })
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ touches }: TouchEvent) => {
        if (touches.length) {
          untap();
        }
      });
  }

  protected parseJSON(jsonObject: CommonJSONEncode): JSONObjectParsed {
    const [x, y, state, id, distance, angle, ...rest] = jsonObject;

    return {
      id,
      x,
      y,
      state,
      distance,
      angle,
      rest
    };
  }

  protected onExtra(extraData: WorldExtraData): void {
    if (!extraData.length) {
      return;
    }

    const [toiletData, teamPoints, startedAt] = extraData;
    toiletData.forEach(([taken, affinity], index) => {
      const sceneToilet = this.level.toilets[index];

      sceneToilet.taken = taken;
      sceneToilet.affinity = affinity as ToiletAffinity;
    });

    this.level.teamPoints = teamPoints;
    this.level.startedAt = startedAt;
  }

  protected onPointerMove(x: number, y: number): void {
    const sprite = this.getPlayerSpritePosition();
    this.mouse = getMouse(x - sprite.x, y - sprite.y);

    if (!this.player) {
      return;
    }

    if (api.physics) {
      if (canMove(this.player)) {
        this.player.target = MyScene.getTargetFromMouse(
          this.mouse,
          this.player
        );
      }
    } else {
      this.emitEvent(this.player, { event: 'mouse', data: this.mouse });
    }
  }
}
