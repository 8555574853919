import { AIConsumer, Button, TScene } from '../utils/model';
import { canMove, canRun, distanceSort, randomFrom, setTarget } from '../utils';
import { takeUntil, throttleTime } from 'rxjs';

import { Constants } from '../utils/constants';
import { GameObject } from '@pietal.dev/engine';
import { Item } from './item.prefab';
import { MyScene } from '../classes/my-scene';
import { Player } from './player.prefab';
import { Vector } from 'detect-collisions';

export class AI extends GameObject {
  isEnemyTeam: (player: Player) => boolean;

  constructor(gameObject: AIConsumer, scene: TScene) {
    super(gameObject.label, gameObject.x, gameObject.y);
    this.label = 'AI';

    this.update$
      .pipe(takeUntil(this.destroy$), throttleTime(Constants.THROTTLE_UPDATE))
      .subscribe(() => {
        if (
          !MyScene.hasPhysics() ||
          Player.hasDeadState(gameObject) ||
          (gameObject instanceof Player && gameObject.isHuman)
        ) {
          return;
        }

        this.onUpdate(gameObject);

        if (gameObject instanceof Player) {
          this.isEnemyTeam = this.getIsEnemyTeam(gameObject, scene);
          this.onUpdatePlayer(gameObject as Player);
        }
      });
  }

  getRandomButton(): Button {
    return (Math.round(Math.random() * Constants.BUTTON_RATIO) + 1) as Button;
  }

  getIsEnemyTeam(player: Player, scene: TScene): (enemy: Player) => boolean {
    return (enemy: Player) => !scene.mapIndex || enemy.team !== player.team;
  }

  getClosestPOI(
    player: Player,
    scene = player.scene as TScene
  ): Vector | undefined {
    const notMyToilets = scene.level.toilets.filter(
      ({ affinity }) => affinity !== (player.team ? 'blue' : 'red')
    );

    return randomFrom(notMyToilets);
  }

  getClosestEnemy(
    consumer: AIConsumer,
    scene = consumer.scene as TScene
  ): Player | undefined {
    const players = scene.players.filter(
      (player) =>
        player !== consumer && player.hp >= 0 && this.isEnemyTeam(player)
    );
    const sort = distanceSort(this.gameObject);
    const [player] = players.slice().sort(sort);

    return player;
  }

  onUpdate(
    consumer: AIConsumer,
    chance: number = Constants.ALL_AI_CHANCE
  ): void {
    if (consumer instanceof Item && Item.hasTriggerState(consumer)) {
      return;
    }

    if (Math.random() < chance) {
      // goto random place
      const x = (Math.random() - Math.random()) * innerWidth;
      const y = (Math.random() - Math.random()) * innerHeight;
      const target = {
        x: consumer.x + x,
        y: consumer.y + y
      };

      if (canMove(consumer)) {
        setTarget(consumer, target);
      }
      if (canRun(consumer)) {
        consumer.state = 'run'; // safe
      }
    }
  }

  onUpdatePlayer(
    consumer: Player,
    chance: number = Constants.PLAYER_AI_CHANCE
  ): void {
    if (Math.random() < chance) {
      setTarget(consumer, this.getClosestPOI(consumer));
    } else if (Math.random() < chance) {
      setTarget(consumer, this.getClosestEnemy(consumer));
    }

    if (!Player.hasTapState(consumer) && Math.random() < chance) {
      consumer.tap(this.getRandomButton());
    }

    if (Player.hasTapState(consumer) && Math.random() < chance) {
      consumer.untap();
    }
  }
}
