import * as PIXI from 'pixi.js';

import { Constants, SpriteSheet, skipWaterTilesGids } from '../utils/constants';
import { JSON, LevelObject } from '../utils/model';
import { PolygonBody, TextureAtlas } from '@pietal.dev/engine';

import { EllipseBody } from './ellipse-body';
import { FixSprite } from './fix-sprite';
import { Level } from './level';
import commonSpriteSheet from '../assets/spritesheet-common.json';
import { createLabel } from '../utils';
import detect from '../utils/detect';
import { textureAtlases } from '../utils/texture-cache';

export class TiledUtils {
  static instance: TiledUtils;

  texturePath: SpriteSheet;

  static getInstance(): TiledUtils {
    if (!TiledUtils.instance) {
      TiledUtils.instance = new TiledUtils();
    }

    return TiledUtils.instance;
  }

  get textureAtlas(): TextureAtlas | null {
    const textureAtlas = textureAtlases[this.texturePath];
    if (!textureAtlas) {
      console.error('TiledUtils: init not run before get frame');

      return null;
    }

    return textureAtlas;
  }

  setTexturePath(texturePath: SpriteSheet): void {
    this.texturePath = texturePath;
  }

  getFrame(gid: number, firstgid = 0): PIXI.Texture {
    return this.textureAtlas.get(gid - firstgid);
  }

  createObjects(levelData: JSON): LevelObject[] {
    const objects: LevelObject[] = [];

    levelData.layers
      .filter(({ type }) => type === 'objectgroup')
      // eslint-disable-next-line
      .forEach(({ name, width, height, ...layer }: JSON) => {
        layer.label = name;
        layer.objects = [...layer.objects.map(this.trimLevelObject(name))];

        objects.push(...layer.objects);
      });

    return objects;
  }

  createTileLayer(layer: JSON, level: Level, firstgid = 1): PIXI.Container {
    const tileLayer = new PIXI.Container();
    const { tileWidth, tileHeight } = commonSpriteSheet;

    tileLayer.label = (layer.label || layer.name).toLowerCase();
    tileLayer.sortableChildren = true;

    layer.data.forEach((gid: number, index: number) => {
      if (skipWaterTilesGids.includes(gid)) {
        return;
      }

      const x = index % layer.width;
      const y = Math.floor(index / layer.width);
      if (detect.isFrontend) {
        const texture = this.getFrame(gid, firstgid);
        const sprite = new FixSprite(texture);

        tileLayer.addChild(sprite);

        sprite.position.set(x * tileWidth, y * tileHeight);
        sprite.scale.set(Constants.TILE_SCALE);
        sprite.label = createLabel(layer.name, sprite);
        sprite.zIndex = sprite.y;
      }

      const collider = level.getCollider(gid);
      if (collider) {
        const gameObject =
          collider.type === 'water' ? level.water : level.walls;
        const body = collider.polygon
          ? new PolygonBody(gameObject, collider.polygon)
          : new EllipseBody(
              gameObject,
              Math.max(collider.width, collider.height) / 2.5
            );

        body.isStatic = true;
        body.setPosition(
          collider.x + x * tileWidth,
          collider.y + y * tileHeight
        );
      }
    });

    return tileLayer;
  }

  trimLevelObject(group: string): (object: LevelObject) => LevelObject {
    // eslint-disable-next-line
    return ({ name, id, type, visible, rotation, ...object }: LevelObject) => ({
      ...object,
      group: group.toLowerCase(),
      label: name
    });
  }
}
