import React, { useEffect } from 'react';

import { Show } from './Show';

export default function App() {
  useEffect(() => {
    const gtag: HTMLScriptElement = document.createElement('script');
    gtag.async = true;
    gtag.src = 'https://www.googletagmanager.com/gtag/js?id=G-Y3W5WQN8EQ';

    const script: HTMLScriptElement = document.createElement('script');
    script.innerHTML = `
    try {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'G-Y3W5WQN8EQ');
    } catch (error) {
      alert(error.message || error);
    }`;

    document.head.appendChild(gtag);
    document.head.appendChild(script);
  }, []);

  return <Show />;
}
