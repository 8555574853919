import { APIProps, TScene } from './model';

import { SocketClient } from '../classes/socket-client';

export class API implements APIProps {
  started: boolean;
  physics: boolean;
  mapIndex: number;
  client: SocketClient;
  scene?: TScene;
}

export const api = new API();
