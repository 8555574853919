export const debugConsole = (selector = '#console') => {
  if (typeof console != 'undefined') {
    // eslint-disable-next-line
    const cons = console as any;
    if (typeof cons.log !== 'undefined') {
      cons.oldLog = cons.log;
    } else {
      cons.oldLog = function () {};
    }

    cons.log = function (...message: string[]) {
      const debug = document.querySelector(selector);

      cons.oldLog(...message);
      debug.innerHTML =
        `${debug.innerHTML}<p>${message.join(' ')}</p>`.substring(0, 256);
    };

    cons.error = cons.debug = cons.info = cons.log;
  }
};
