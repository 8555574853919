import React, { FC, useState } from 'react';

import { Button } from './Button';

export const Menu: FC<{
  name: string;
  setName: (name: string) => void;
  play: (mapIndex: number) => void;
}> = ({ name, setName, play }) => {
  const [visible, show] = useState('play');

  return (
    <section id="menu">
      <div className="square min-h-full md:min-h-[auto] md:max-h-[500px] md:h-full">
        <div className="buttons">
          <Button
            className="!hidden md:!inline-block"
            name="play"
            type="red"
            current={visible}
            setCurrent={show}
          />
          <Button
            className="!hidden md:!inline-block"
            disabled
            name="top 100"
            type="brown"
            current={visible}
            setCurrent={show}
          />
          <div className="space-for-logo min-h-[180px] landscape:min-h-[150px] md:!min-h-[auto]">
            <div className="logo bg-logo"></div>
          </div>
          <Button
            className="!hidden md:!inline-block"
            disabled
            name="progress"
            type="brown"
            current={visible}
            setCurrent={show}
          />
          <Button
            className="!hidden md:!inline-block"
            disabled
            name="shop"
            type="brown"
            current={visible}
            setCurrent={show}
          />
        </div>
        <div className="flex relative justify-between align-center grow">
          <div className="ornament-up"></div>
          <div className="flex flex-col gap-4 justify-center items-center overflow-auto p-10 leading-9">
            <p>Ready to party hard?</p>
            <input
              type="text"
              placeholder="type viking name here"
              value={name}
              className="block rounded-md p-3 font-2xl bg-black opacity-60 w-full"
              onChange={({ target }) => {
                setName(target.value || '');
              }}
            />
            <div className="flex flex-col sm:flex-row gap-4 items-center sm:items-stretch justify-center max-w-full w-[500px]">
              <Button
                name="folk concert"
                type="red"
                current={visible}
                setCurrent={() => play(0)}
              />
              <Button
                name="latrine domination"
                type="red"
                current={visible}
                setCurrent={() => play(1)}
              />
            </div>
          </div>
          <div className="ornament-down"></div>
        </div>
      </div>
    </section>
  );
};
