import { CircleBody, GameObject, StateMachine } from '@pietal.dev/engine';
import { Collider, Mouse, TScene } from '../utils/model';
import {
  Constants,
  allowDropItemStates,
  deadItemStates,
  droppedItemStates,
  explodeItemStates,
  noPushbackItemStates,
  ownerlessItemStates,
  sinkItemStates,
  targetlessStates,
  triggerItemStates
} from '../utils/constants';
import { Sound, SoundPlayer } from '../classes/sound-player';
import { followMouse, updateSprite } from '../utils/mouse';

import { AI } from './ai.prefab';
import { EllipseBody } from '../classes/ellipse-body';
import { MyAnimator } from '../classes/my-animator';
import { MyScene } from '../classes/my-scene';
import { Player } from './player.prefab';
import { Response } from 'detect-collisions';
import detect from '../utils/detect';

export class Item extends GameObject {
  id: string;
  lastUpdate = Date.now();
  isBehindBush = false;
  body: CircleBody;
  target?: Mouse;
  stateMachine?: StateMachine;
  sprite?: MyAnimator;
  ai?: AI;
  owner?: Player;
  isOnFire?: boolean;

  static hasTriggerState({ state }: Pick<Item, 'state'>): boolean {
    return triggerItemStates.includes(state);
  }

  static hasOwnerlessState({ state }: Pick<Item, 'state'>): boolean {
    return ownerlessItemStates.includes(state);
  }

  static hasTargetlessState({ state }: Pick<Item, 'state'>): boolean {
    return targetlessStates.includes(state);
  }

  static hasDroppedState({ state }: Pick<Item, 'state'>): boolean {
    return droppedItemStates.includes(state);
  }

  static hasExplodeState({ state }: Pick<Item, 'state'>): boolean {
    return explodeItemStates.includes(state);
  }

  static hasDeadState({ state }: Pick<Item, 'state'>): boolean {
    return deadItemStates.includes(state);
  }

  static hasSinkState({ state }: Pick<Item, 'state'>): boolean {
    return sinkItemStates.includes(state);
  }

  static hasAllowDropState({ state }: Pick<Item, 'state'>): boolean {
    return allowDropItemStates.includes(state);
  }

  static hasPushbackState({ state }: Pick<Item, 'state'>): boolean {
    return !noPushbackItemStates.includes(state);
  }

  get state(): string {
    return (this.stateMachine || this.sprite).state;
  }

  set state(state: string) {
    this.setState(state);
  }

  constructor(
    scene: TScene,
    { x, y, id, group }: Pick<Collider, 'x' | 'y' | 'group'> & { id?: string }
  ) {
    super(group, x, y);

    this.id = id || `${Math.round(x)},${Math.round(y)}`;
    this.body = new EllipseBody(this);
    this.createStateContainer();

    // @TODO ?
    // item.particles = await createParticles(item, ["walk", "fire", "boom"]);

    if (group.match(/baby|chicken/)) {
      this.ai = new AI(this, scene);
      scene.addChild(this.ai);
    }

    scene.addChild(this);

    console.log('created item:', this.label);
  }

  destroy(): void {
    super.destroy();

    const { scene } = this;
    if (this.body) {
      scene?.physics.remove(this.body);
      this.body.destroy();
    }

    if (this.ai) {
      this.ai.destroy();
    }

    this.sprite?.destroy();
    this.stateMachine?.destroy();
  }

  update(deltaTime: number): void {
    super.update(deltaTime);

    const now = Date.now();
    const trueDeltaTime = (now - this.lastUpdate) / Constants.FPS_60_MS;
    this.lastUpdate = now;

    followMouse(this, trueDeltaTime);
    updateSprite(this, trueDeltaTime);
  }

  isOnWater(): boolean {
    const { level } = this.scene as TScene;
    let sink = level.isOnWater(this);

    if (!sink) {
      this.body.system?.checkOne(
        this.body,
        ({ b: { gameObject } }: Response) => {
          if (gameObject.label === 'water') {
            sink = true;
          }
        }
      );
    }

    return sink;
  }

  setState(state: string, loop = true, stateWhenFinished = 'idle'): boolean {
    if (this.state === state) {
      return true;
    }

    const ok = !!(this.sprite || this.stateMachine).setState(
      state,
      loop,
      stateWhenFinished
    );

    if (ok) {
      const isTrigger = Item.hasTriggerState(this);
      const isChicken = this.label === 'chicken';

      this.body.isTrigger = isTrigger;

      if (Item.hasOwnerlessState(this)) {
        this.owner = null;
      }

      if (Item.hasTargetlessState(this)) {
        this.target = null;
      }

      if (
        isChicken &&
        (Item.hasExplodeState(this) || Item.hasDroppedState(this))
      ) {
        SoundPlayer.play(Sound.CHICKEN, this);
      }

      if (this.state === 'explode') {
        SoundPlayer.play(isChicken ? Sound.CHICKEN : Sound.WOOD, this);
      }

      if (this.state === 'sink') {
        SoundPlayer.play(Sound.SINK, this);
      }
    }

    return ok;
  }

  createItemAnimator(): MyAnimator {
    const animator = new MyAnimator(this);

    animator.setState('carry');
    animator.animation.anchor.set(0.5, Constants.CARRY_ANCHOR_Y);
    animator.setState('idle');

    return animator;
  }

  createStateContainer(): void {
    if (detect.isFrontend) {
      this.sprite = this.createItemAnimator();
    } else {
      this.stateMachine = new StateMachine(this, 'idle');
    }
  }

  respawn(): void {
    const [x, y] = this.id.split(',').map((string) => Number(string));

    this.body.setPosition(x, y);
    this.state = 'idle';

    MyScene.separateBody(this.body);
  }

  ghost(): void {
    if (this.state === 'ghost') {
      return;
    }

    this.state = 'ghost'; // safe

    if (this.state === 'ghost') {
      setTimeout(() => {
        this.respawn();
      }, Constants.RESPAWN_ITEM_MS);
    }
  }

  sink(): void {
    if (Item.hasSinkState(this)) {
      return;
    }

    this.state = 'sink';

    setTimeout(() => {
      this.ghost();
    }, Constants.EXPLODE_ITEM_MS);
  }

  drop(): void {
    if (!Item.hasAllowDropState(this) || Item.hasDroppedState(this)) {
      return;
    }

    if (this.isOnWater()) {
      this.sink();
    } else {
      this.state = 'dropped';
      MyScene.separateBody(this.body);

      setTimeout(() => {
        if (this.state === 'dropped') {
          this.state = 'idle';
        }
      }, Constants.DROPPED_ITEM_MS);
    }
  }

  explode(): void {
    if (Item.hasExplodeState(this) || Item.hasDeadState(this)) {
      return;
    }

    this.state = 'explode';

    setTimeout(() => {
      if (this.label === 'chicken') {
        if (this.isOnWater()) {
          this.sink();
        } else {
          this.state = 'idle';
        }
      } else {
        this.ghost();
      }
    }, Constants.EXPLODE_ITEM_MS);
  }
}
