import './assets/index.scss';

import React, { StrictMode } from 'react';

import App from './components/App';
import { MyScene } from './classes/my-scene';
import { createRoot } from 'react-dom/client';
import { debugConsole } from './classes/console';
import register from 'offline-worker';

const rootElement = document.getElementById('root');

if (rootElement) {
  createRoot(rootElement).render(
    <StrictMode>
      <App />
      <div id="console"></div>
    </StrictMode>
  );

  register('./worker.js');

  if ('console' in MyScene.getQueryParams()) {
    debugConsole();
  }
}
