import { AIConsumer, Mouse } from './model';
import { canRun, getFlipX, lerp } from '.';

import { Constants } from './constants';
import { Item } from '../prefabs/item.prefab';
import { MyScene } from '../classes/my-scene';
import { Player } from '../prefabs/player.prefab';
import detect from '../utils/detect';
import { encodeFloat } from './encode';

export function getMouse(diffX: number, diffY: number): Mouse | null {
  if (!diffX && !diffY) {
    return null;
  }

  const distance = encodeFloat(Math.hypot(diffX, diffY));
  const angle = Math.atan2(diffY, diffX);
  const x = encodeFloat((Math.cos(angle) * distance) / (innerWidth / 2));
  const y = encodeFloat((Math.sin(angle) * distance) / (innerHeight / 2));

  // mouse x, y is normalized into -1..1 range
  return { x, y, angle: encodeFloat(angle), distance };
}

export function getSpeed(gameObject: AIConsumer): number {
  // state = died, etc.
  let speed = 0;

  switch (gameObject.state) {
    case 'sink':
      speed = 0.45;
      break;

    case 'prep':
    case 'carry':
    case 'explode':
      speed = 0.625;
      break;

    case 'run':
      speed = gameObject.label.match(/baby|chicken/i) ? 0.625 : 1.25;
      break;

    case 'hit':
    case 'struck':
      speed = 2.5;
      break;

    case 'dash':
    case 'thor':
    case 'punch':
      speed = 3.6;
      break;

    case 'fly':
      speed = 4;
      break;
  }

  if (
    gameObject instanceof Player &&
    (gameObject.isBeesPU || gameObject.isBerserk)
  ) {
    speed *= 1.2;
  }

  return speed;
}

export function followMouse(gameObject: AIConsumer, deltaTime: number): void {
  if (!gameObject.target) {
    if (canRun(gameObject)) {
      gameObject.state = 'idle';
    }
    return;
  }

  const { body, target } = gameObject;
  const near = Math.min(1, target.distance / Constants.MOUSE_LIMIT);

  body.angle = target.angle;

  let distance = deltaTime * getSpeed(gameObject) * near;
  do {
    const step = Math.min(
      target.distance,
      distance,
      Constants.MAX_MOVE_SPEED_PX
    );
    body.move(step);
    MyScene.separateBody(body);

    target.distance -= step;
    distance -= Constants.MAX_MOVE_SPEED_PX;
  } while (distance > 0);

  if (canRun(gameObject)) {
    gameObject.state =
      target.distance < Constants.MIN_PX_IDLE_TO_RUN ? 'idle' : 'run'; // safe
  }
}

export function updateSprite(gameObject: AIConsumer, deltaTime: number): void {
  if (!detect.isFrontend) {
    return;
  }

  const { sprite, info } = gameObject as Player;
  if (!sprite) {
    return;
  }

  const isCarryItem =
    gameObject instanceof Item && gameObject.state === 'carry';

  if (!isCarryItem) {
    sprite.setScale(getFlipX(gameObject), 1);
  }

  let alpha = 1;

  if (gameObject.state === 'died') {
    alpha = Constants.DIED_ALPHA;
  }

  if (gameObject.state === 'ghost') {
    alpha = 0;
  }

  if (gameObject.isBehindBush) {
    alpha *= 0.2;
  }

  sprite.alpha = lerp(sprite.alpha, alpha, Constants.ALPHA_FADE * deltaTime);

  if (info) {
    info.alpha = sprite.alpha;
  }

  sprite.zIndex = sprite.y;
}
