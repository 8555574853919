import * as PIXI from 'pixi.js';

import { AnimationConfig, JSON } from './model';
import { Resources, TextureAtlas } from '@pietal.dev/engine';

import { SpriteSheet } from './constants';
import commonSpriteSheet from '../assets/spritesheet-common.json';

export const textures: Record<string, PIXI.Texture> = {};

export const textureAtlases: Record<string, TextureAtlas> = {};

export function getIndexOfFrame(data: JSON, name: string): number {
  const { frame } = data.frames[name];
  const x = Math.floor(frame.x / frame.w);
  const y = Math.floor(frame.y / frame.h);
  const cols = data.width / data.tilewidth;

  return y * cols + x;
}

export function getAnimations(
  animationData: JSON,
  prefix: string = 'hero0'
): AnimationConfig {
  return Object.entries(animationData.animations).reduce(
    (animations, [state, states]: [string, string[]]) => {
      return {
        ...animations,
        [state]: states.map((animation) =>
          getIndexOfFrame(animationData, `${prefix}${animation}`)
        )
      };
    },
    {} as AnimationConfig
  );
}

const MINECRAFT_FONT_PATH = '/assets/font/';

export enum Fonts {
  MONOGRAM_TTF = `${MINECRAFT_FONT_PATH}/monogram/monogram.ttf`
  // MAGO1_TTF = `${MINECRAFT_FONT_PATH}/mago/mago1.ttf`,
  // MAGO2_TTF = `${MINECRAFT_FONT_PATH}/mago/mago2.ttf`,
  // MAGO3_TTF = `${MINECRAFT_FONT_PATH}/mago/mago3.ttf`,
  // MC_EOT = `${MINECRAFT_FONT_PATH}/mc/Minecraft.eot`,
  // MC_TTF = `${MINECRAFT_FONT_PATH}/mc/Minecraft.ttf`,
  // MC_WOFF = `${MINECRAFT_FONT_PATH}/mc/Minecraft.woff`
}

export async function prepareCache(): Promise<void> {
  await Promise.all(
    Object.values(Fonts).map((font) => Resources.loadResource(font))
  );

  const spriteSheets = [SpriteSheet.HEART, SpriteSheet.WHITE];
  const loadedSpriteSheets = await Promise.all(
    spriteSheets.map((spriteSheet) => Resources.loadResource(spriteSheet))
  );

  loadedSpriteSheets.forEach((texture, index) => {
    const spriteSheet = spriteSheets[index];

    textures[spriteSheet] = texture;
  });

  const paths = [
    SpriteSheet.FOLK_CONCERT,
    SpriteSheet.LATRINE_DOMINATION,
    SpriteSheet.HATS
  ].filter((path) => !textureAtlases[path] || !textures[path]);

  const promises: Promise<PIXI.Texture>[] = paths.map(
    async (path) => await Resources.loadResource(path)
  );

  const results = await Promise.all(promises);

  results.forEach((texture: PIXI.Texture, index: number) => {
    const path = paths[index];

    textures[path] = texture;
    textureAtlases[path] = new TextureAtlas({
      ...commonSpriteSheet,
      texture
    });
  });
}
