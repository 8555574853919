import React, { FC } from 'react';

import { Game } from './Game';
import { MyScene } from '../classes/my-scene';

export const Show: FC = () => {
  const { show, ...params } = MyScene.getQueryParams();
  const showNr = Number(show || 1);
  const path = `./?${Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join('&')}`;

  if (showNr > 1) {
    return (
      <div className="flex flex-col landscape:flex-row w-screen h-screen">
        {Array.from({ length: showNr }, (_: undefined, index: number) => (
          <iframe key={index} className="border-0 flex-1" src={path} />
        ))}
      </div>
    );
  }

  return <Game />;
};
