import * as PIXI from 'pixi.js';

import {
  AIConsumer,
  Binary,
  Button,
  Mouse,
  PlayerProps,
  Skill,
  TScene
} from '../utils/model';
import {
  CircleBody,
  GameObject,
  StateMachine,
  TextureAtlas
} from '@pietal.dev/engine';
import {
  Constants,
  SpriteSheet,
  animatorAnchor,
  animatorConfig,
  beardFrames,
  beardPositions,
  deadStates,
  hitStates,
  tapStates,
  targetlessStates
} from '../utils/constants';
import { Sound, SoundPlayer } from '../classes/sound-player';
import { canRun, getFlipX, getTargetBeardRotation, lerp } from '../utils';
import { followMouse, updateSprite } from '../utils/mouse';

import { AI } from './ai.prefab';
import { EllipseBody } from '../classes/ellipse-body';
import { FixSprite } from '../classes/fix-sprite';
import { InfoContainer } from './info.prefab';
import { Item } from './item.prefab';
import { MyAnimator } from '../classes/my-animator';
import { MyScene } from '../classes/my-scene';
import { SocketClient } from '../classes/socket-client';
import { Subject } from 'rxjs';
import { Vector } from 'detect-collisions';
import detect from '../utils/detect';
import { randomAdjective } from 'sillyname';
import { textureAtlases } from '../utils/texture-cache';

export class Player extends GameObject implements PlayerProps {
  protected static names: string[] = [];

  id: string;
  update$: Subject<number> = new Subject();
  destroy$: Subject<void> = new Subject();
  sessionAchievements: string[] = [];
  achievements: string[] = [];
  lastHitBy = '';
  lastUpdate = Date.now();
  team: Binary = 0;
  sessionCoins = 0;
  sessionDeaths = 0;
  sessionKills = 0;
  sessionCombo = 0;
  coins = 0;
  deaths = 0;
  kills = 0;
  combo = 0;
  score = 0;
  levelScore = 0;
  life = 0;
  hp = 5;
  maxHP = 5;
  power = 0;
  isHuman = false;
  isBoss = false;
  isThor = false;
  isThorPU = false;
  isBeesPU = false;
  isBerserk = false;
  isBehindBush = false;
  hats: boolean[] = [true];
  skills: Skill[] = [];
  beardLength: number;
  hatIndex: number;
  body: CircleBody;
  timeout?: NodeJS.Timeout;
  sprite?: MyAnimator;
  target?: Mouse;
  stateMachine?: StateMachine;
  pick?: Item;
  prepareSince?: number;
  destroyAt?: number;
  client?: Pick<SocketClient, 'socketio'>;
  ai?: AI;
  beard?: PIXI.Sprite;
  hat?: PIXI.Sprite;
  aura?: PIXI.Container;
  info?: InfoContainer;
  name?: PIXI.Text;

  static hasDeadState({ state }: Pick<Player, 'state'>): boolean {
    return deadStates.includes(state);
  }

  static hasHitState({ state }: Pick<Player, 'state'>): boolean {
    return hitStates.includes(state);
  }

  static hasTapState({ state }: Pick<Player, 'state'>): boolean {
    return tapStates.includes(state);
  }

  static hasTargetlessState({ state }: Pick<Player, 'state'>): boolean {
    return targetlessStates.includes(state);
  }

  static getRandomName(scene: TScene): string {
    let name: string = '';

    do {
      name = randomAdjective();
    } while (scene.playerNames?.includes(name));

    Player.useName(scene, name);

    return name;
  }

  static getBeardLength(input = -1): number {
    return Number(
      input !== -1
        ? input % Constants.MAX_BEARD_LENGTH
        : Math.floor(Math.random() * Constants.MAX_BEARD_LENGTH)
    );
  }

  static getHatIndex(input = -1): number {
    return input !== -1
      ? input % Constants.MAX_HAT_INDEX
      : Math.floor(Math.random() * Constants.MAX_HAT_INDEX);
  }

  static isBlueTeam(player: Player): boolean {
    return !!(player.team % 2);
  }

  static isMainPlayer(player: Player): boolean {
    const scene = player.scene as TScene;

    return player === scene?.player;
  }

  static freeName(scene: TScene, name: string): void {
    if (!scene.playerNames) {
      scene.playerNames = [];
    }

    const index = scene.playerNames.indexOf(name);
    if (index !== -1) {
      scene.playerNames.splice(index, 1);
    }
  }

  static useName(scene: TScene, name: string): void {
    if (!scene.playerNames) {
      scene.playerNames = [];
    }

    if (!scene.playerNames.includes(name)) {
      scene.playerNames.push(name);
    }
  }

  get state(): string {
    return (this.stateMachine || this.sprite).state;
  }

  set state(state: string) {
    try {
      if (this.setState(state)) {
        this.updateBeardAndHat();
      }
      // eslint-disable-next-line
    } catch (_error) {
      console.warn(`can't set state to: ${state}`);
    }
  }

  constructor(
    scene: TScene,
    { id, team, beardLength, hatIndex } = {
      id: Player.getRandomName(scene),
      team: (scene.mapIndex ? ++scene.level.lastTeam % 2 : 0) as Binary,
      beardLength: Player.getBeardLength(),
      hatIndex: Player.getHatIndex()
    }
  ) {
    const { x, y } = scene.level.getNextSpawn(team);
    super(`hero${team}`, x, y);

    this.id = id;
    this.team = team;
    this.body = new EllipseBody(this);
    this.setBeard(beardLength);
    this.setHat(hatIndex);
    this.createStateContainer();

    if (detect.isFrontend) {
      this.info = new InfoContainer(this);
    }

    // resetPlayer(player);
    // addPlayerFunctions(player, scene.mapIndex);
    // extendPlayer(player, playerData);
    //
    // this.aura = createAura(player, auraFrames.player, texture);
    // this.aura.setState('prep');
    // this.aura.animation.animationSpeed = Delay.PREP_ANIM_SPEED;
    //
    // this.thunder = await createThunderSprite(player);
    //
    // this.particles = createParticles(this, playerParticles);

    this.ai = new AI(this, scene);
    scene.addChild(this.ai);
    scene.addChild(this);

    console.log('created player:', this.label);
  }

  destroy(): void {
    super.destroy();

    const { scene } = this;
    if (this.body) {
      scene?.physics.remove(this.body);
      this.body.destroy();
    }

    if (this.ai) {
      this.ai.destroy();
    }

    this.beard?.destroy();
    this.hat?.destroy();
    this.sprite?.destroy();
    this.stateMachine?.destroy();
  }

  setState(next: string, loop = true, stateWhenFinished = 'idle'): boolean {
    const state = process.env.STATE || next;
    if (state === this.state) {
      return true;
    }

    if (process.env.LOG_STATE && this.isHuman) {
      console.log(this.state, 'to', state, new Error().stack);
    }

    const ok = !!(this.sprite || this.stateMachine).setState(
      state,
      loop,
      stateWhenFinished
    );

    if (ok) {
      const { isTrigger: wasTrigger } = this.body;
      const isTrigger = Player.hasDeadState(this);

      this.body.isTrigger = isTrigger;
      if (wasTrigger && !isTrigger) {
        MyScene.separateBody(this.body);
      }

      if (this.state !== 'carry') {
        this.dropPick();
      }

      if (Player.hasTargetlessState(this)) {
        this.target = null;
      }

      if (Player.hasHitState(this)) {
        SoundPlayer.play(Sound.HIT, this);
      }

      if (this.state === 'tired') {
        SoundPlayer.play(Sound.THROW, this);
      }
    }

    return ok;
  }

  setValidators(): void {
    const target = this.sprite?.stateMachine || this.stateMachine;
    const validator = (to: string) => {
      // online validators
      if (MyScene.hasPhysics()) {
        const from = this.state;
        if (['prep', 'block'].includes(to)) {
          return ['idle', 'run', 'blocked'].includes(from);
        }

        if (['punch', 'thor'].includes(to)) {
          return from === 'prep';
        }

        if (['hit', 'struck'].includes(to)) {
          return ![
            'hit',
            'struck',
            'dying',
            'died',
            'block',
            'blocked'
          ].includes(from);
        }

        if (to === 'tired') {
          return from === 'carry';
        }

        if (to === 'run') {
          return ['idle', 'blocked'].includes(from);
        }
      }

      return true;
    };

    target.setValidators('*', [validator]);
  }

  setSpriteAnchor(sprite: PIXI.Sprite): void {
    sprite.anchor.set(animatorAnchor.hero0.x, animatorAnchor.hero0.y);
  }

  setBeard(beardLength: number): void {
    const newLength = Math.min(
      Constants.MAX_BEARD_LENGTH,
      Math.max(0, beardLength)
    );

    if (this.isBoss || (this.beard && this.beardLength === newLength)) {
      return;
    }

    if (newLength > this.beardLength) {
      SoundPlayer.play(Sound.LAUGH, this);
    }

    this.beardLength = newLength;
    this.skills.forEach((skill: Skill, skillIndex: number) => {
      skill.available = this.beardLength >= skillIndex * 5;
    });

    if (detect.isFrontend) {
      const texture = this.getTextureAtlas().get(beardFrames[this.beardLength]);
      if (!this.beard) {
        this.beard = new FixSprite(texture);
        this.setSpriteAnchor(this.beard);
      } else {
        this.beard.texture = texture;
      }
    }
  }

  setHat(hatIndex: number): void {
    if (this.isBoss || (this.hat && this.hatIndex === hatIndex)) {
      return;
    }

    this.hatIndex = hatIndex;
    this.hats[hatIndex] = true;

    if (detect.isFrontend) {
      const texture = textureAtlases[SpriteSheet.HATS].get(hatIndex);
      if (!this.hat) {
        this.hat = new FixSprite(texture);
        this.setSpriteAnchor(this.hat);
      } else {
        this.hat.texture = texture;
      }
    }
  }

  setHP(hp: number, damager?: AIConsumer): void {
    this.hp = hp;
    this.lastHitBy = damager?.id || '';
  }

  onCollidePlayer(player: Player): void {
    if (
      !player ||
      ((this.scene as TScene).mapIndex && player.team === this.team)
    ) {
      return;
    }

    if (
      !['punch', 'thor'].includes(this.state) ||
      Player.hasHitState(player) ||
      Player.hasDeadState(player)
    ) {
      return;
    }

    if (['block', 'blocked'].includes(player.state)) {
      player.state = 'blocked'; // safe
      player.timeout = setTimeout(() => {
        player.state = 'block'; // safe
      }, Constants.PLAYER_BLOCK_DURATION);
    } else {
      const power = Math.ceil(this.power);

      player.damage(this, power);
    }
  }

  onCollideItem(item: Item): void {
    if (!item || item.label === 'baby') {
      return;
    }

    if (item.state === 'fly') {
      this.damage(item);

      item.explode();
    } else if (!this.pick && canRun(item) && canRun(this)) {
      this.state = 'carry';
      this.pick = item;
      this.updatePick();

      item.state = 'carry';
      item.owner = this;
    }
  }

  getTexturePath(): SpriteSheet {
    return this.team
      ? SpriteSheet.LATRINE_DOMINATION
      : SpriteSheet.FOLK_CONCERT;
  }

  getTextureAtlas(): TextureAtlas {
    const texturePath = this.getTexturePath();

    return textureAtlases[texturePath];
  }

  getBeardPosition(): Vector {
    const { currentFrame } = this.sprite.animation;
    const states: number[] = animatorConfig[this.label][this.state];
    const beardFrame = states[currentFrame % states.length];
    const { offset, pivot, [beardFrame]: beardPosition } = beardPositions;

    return {
      x: beardPosition.x - (offset.x - pivot.x),
      y: beardPosition.y - (offset.y - pivot.y)
    };
  }

  createPlayerAnimator(): MyAnimator {
    return new MyAnimator(this, this.getTextureAtlas());
  }

  createStateContainer(): void {
    if (detect.isFrontend) {
      this.sprite = this.createPlayerAnimator();
      this.sprite.addChild(this.beard);
      this.sprite.addChild(this.hat);
    } else {
      this.stateMachine = new StateMachine(this, process.env.STATE || 'idle');
    }

    this.setValidators();
  }

  update(deltaTime: number): void {
    super.update(deltaTime);

    this.checkDeath();

    const now = Date.now();
    const trueDeltaTime = (now - this.lastUpdate) / Constants.FPS_60_MS;
    this.lastUpdate = now;

    followMouse(this, trueDeltaTime);
    updateSprite(this, trueDeltaTime);

    this.updateBeardAndHat();
    this.updatePick();

    // updateAura(player);
    // updateAuraTint(player);
    // updateThunder(player);
    // updateParticles(player);
  }

  updateBeardAndHat(): void {
    if (!detect.isFrontend) {
      return;
    }

    const flip = getFlipX(this);
    const { x, y } = this.getBeardPosition();
    const { pivot, deadPivot } = beardPositions;

    this.beard.position.set(flip * x, y);
    this.hat.position.set(flip * x, y);

    if (Player.hasDeadState(this)) {
      this.hat.pivot.set(flip * deadPivot.x + 1, deadPivot.y);
      this.beard.pivot.set(flip * deadPivot.x + 1, deadPivot.y);
      this.hat.scale.y = flip;
      this.beard.scale.y = flip;
      this.hat.rotation = Constants.DEAD_ROTATION;
      this.beard.rotation = Constants.DEAD_ROTATION;
    } else {
      this.hat.pivot.set(pivot.x, pivot.y);
      this.beard.pivot.set(pivot.x, pivot.y);
      this.hat.scale.y = 1;
      this.beard.scale.y = 1;
      this.hat.rotation = 0;
      if (this.beard.rotation === Constants.DEAD_ROTATION) {
        this.beard.rotation = getTargetBeardRotation(this);
      } else {
        this.beard.rotation = lerp(
          this.beard.rotation,
          getTargetBeardRotation(this),
          0.05
        );
      }
    }
  }

  updatePick(): void {
    if (this.pick) {
      this.pick.body.setPosition(this.x, this.y);

      if (this.pick?.sprite && this.sprite) {
        this.pick.sprite.setScale(this.sprite.scale.x, 1);
      }
    }
  }

  tap(button: Button): void {
    if (Player.hasDeadState(this)) {
      return;
    }

    // lmb
    if (button < 2) {
      this.throwPick();
    }

    this.state = button < 2 ? 'prep' : 'block';

    if (this.state === 'prep') {
      this.prepareSince = Date.now();
    }
  }

  untap(): void {
    if (Player.hasDeadState(this)) {
      return;
    }

    const state =
      this.state === 'prep' ? (this.isThor ? 'thor' : 'punch') : 'idle';
    this.state = state;

    if (['punch', 'thor'].includes(state)) {
      const duration = Date.now() - this.prepareSince;

      this.power = Math.min(
        Constants.PUNCH_POWER_MAX,
        duration / Constants.PUNCH_ONE_LAYER
      );
      this.prepareSince = 0;

      this.timeout = setTimeout(() => {
        this.power = 0;

        if (['punch', 'thor'].includes(this.state)) {
          this.state = 'idle'; // safe
        }
      }, Constants.PUNCH_DURATION_1STR * this.power);
    }
  }

  throwPick(): void {
    if (MyScene.hasPhysics() && this.pick) {
      const pick = this.pick;
      const { angle } = this.target || this.body;

      pick.state = 'fly';
      pick.target = {
        distance: Constants.THROW_ITEM_MS,
        angle
      } as Mouse;

      setTimeout(() => {
        pick.drop();
      }, Constants.THROW_ITEM_MS);

      this.pick = null;
      this.tired(); // safe
    }
  }

  dropPick(): void {
    if (MyScene.hasPhysics() && this.pick) {
      this.pick.drop();
      this.pick = null;

      if (this.state === 'carry') {
        this.state = 'idle';
      }
    }
  }

  checkDeath(): void {
    if (!MyScene.hasPhysics()) {
      return;
    }

    if (this.hp <= 0 && !Player.hasDeadState(this)) {
      this.state = 'dying'; // safe

      const scene = this.scene as TScene;
      const damager = scene.players.find(
        (player) => player.id === this.lastHitBy
      );

      if (damager instanceof Player) {
        damager.setBeard(damager.beardLength + 1);
      }

      this.timeout = setTimeout(() => {
        this.state = 'died'; // safe
        this.timeout = setTimeout(() => {
          this.state = 'ghost'; // safe
          this.timeout = setTimeout(() => {
            this.setBeard(this.beardLength - 1);
            scene.level.respawn(this);
          }, Constants.GHOST_STATE_DURATION);
        }, Constants.DIED_STATE_DURATION);
      }, Constants.DYING_STATE_DURATION);
    }
  }

  tired(): void {
    this.state = 'tired'; // safe

    this.timeout = setTimeout(() => {
      if (this.state === 'tired') {
        this.state = 'idle'; // safe
      }
    }, Constants.PLAYER_TIRED_DURATION);
  }

  damage(object: AIConsumer, power = 1): void {
    const scene = this.scene as TScene;
    const damager = object instanceof Item ? object.owner : object;
    if (scene.mapIndex && damager?.team === this.team) {
      return;
    }

    const angle = Math.atan2(this.y - object.y, this.x - object.x);
    this.target = MyScene.getTargetFromAngle(angle);
    this.state = object.state === 'thor' ? 'struck' : 'hit';
    this.timeout = setTimeout(() => {
      this.setHP(this.hp - power, damager);
      if (this.hp > 0) {
        this.state = 'idle'; // safe
      }
    }, Constants.PLAYER_HIT_DURATION);
  }

  respawn({ x, y }: Vector): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    this.dropPick();
    this.info?.hideHearts();
    this.score = 0;
    this.combo = 0;
    this.body.setPosition(x, y);
    this.state = 'idle'; // safe
    this.setHP(this.maxHP);

    MyScene.separateBody(this.body);
  }
}
