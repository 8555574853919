import {
  AIConsumer,
  AnyType,
  Binary,
  Mouse,
  SimpleArray,
  TScene,
  ToiletData,
  WorldExtraData
} from './model';

import { Item } from '../prefabs/item.prefab';
import { Player } from '../prefabs/player.prefab';
import { getMouse } from './mouse';

export function decodeMouse(mouse: number[] | number): Mouse | null {
  if (!Array.isArray(mouse)) {
    return null;
  }

  const [mouseX, mouseY, distance] = mouse;
  const mousePosX = mouseX * distance;
  const mousePosY = mouseY * distance;

  return getMouse(mousePosX, mousePosY);
}

export function encodeBoolean(bool: boolean): Binary {
  return bool ? 1 : 0;
}

export function encodeFloat(float: number): number {
  return Number(float.toFixed(3));
}

export function encodeCommon(entry: AIConsumer): SimpleArray {
  return [
    encodeFloat(entry.x),
    encodeFloat(entry.y),
    entry.state || '',
    entry.id || '',
    encodeFloat(entry.target?.distance || 0),
    encodeFloat(entry.target?.angle || entry.body.angle || 0)
  ];
}

export function encodeItem(entry: Item): SimpleArray {
  return [...encodeCommon(entry), entry.label, encodeBoolean(entry.isOnFire)];
}

export function encodePlayer(entry: Player): SimpleArray {
  const powerups = [
    entry.isThor,
    entry.isBerserk,
    entry.isBeesPU,
    entry.isThorPU
  ].map(encodeBoolean);
  const now = Date.now();
  const cooldowns = entry.skills?.map(({ cooldown }) =>
    cooldown && cooldown > now ? encodeFloat(cooldown - now) : 0
  );

  return [
    ...encodeCommon(entry),
    entry.team,
    entry.beardLength,
    entry.hatIndex,
    encodeBoolean(entry.isHuman),
    entry.hp,
    entry.pick?.id || '',
    ...powerups,
    ...cooldowns
  ];
}

// TODO remove ?
export function encodePlayerExtra(entry: Player): AnyType[] {
  if (!entry.isHuman) {
    return [entry.score, entry.levelScore];
  }

  const array: AnyType[] = [
    entry.score,
    entry.levelScore,
    entry.coins,
    entry.life,
    entry.combo,
    entry.achievements
  ];

  if (entry.hp <= 0) {
    array.push(entry.hats);
    array.push(entry.sessionAchievements);
    array.push(entry.sessionCoins);
    array.push(entry.sessionDeaths);
    array.push(entry.sessionKills);
    array.push(entry.sessionCombo);
  }

  return array;
}

export function encodeWorldExtra(scene: TScene): WorldExtraData {
  const toilets: ToiletData[] = scene.level.toilets.map(
    ({ taken, affinity }) => [taken || 0, affinity || '']
  );

  return [toilets, scene.level.teamPoints, scene.level.startedAt];
}
