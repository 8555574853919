import { AIConsumer, TScene } from '../utils/model';

import { Constants } from '../utils/constants';
import { Howl } from 'howler';
import { Vector } from 'detect-collisions';
import detect from '../utils/detect';
import { randomFrom } from '../utils';

export enum Sound {
  BUTTON = 'button',
  CHICKEN = 'chicken',
  THROW = 'throw',
  LAUGH = 'laugh',
  WOOD = 'wood',
  SINK = 'sink',
  HIT = 'hit'
}

export type SoundEntry = `${Sound}${number}`;

export type SoundConfig<T> = Record<SoundEntry, T>;

export type SoundSource = Omit<AIConsumer, 'scene'> & { scene: TScene };

export type SoundPosition = Vector & { range: number };

export const soundConfig: SoundConfig<string> = {
  button0: 'button',
  chicken0: 'chicken',
  throw0: 'throw',
  wood0: 'wood',
  sink0: 'sink1',
  sink1: 'sink2',
  sink2: 'sink3',
  sink3: 'sink4',
  hit0: 'hit',
  laugh0: 'laugh1',
  laugh1: 'laugh2',
  laugh2: 'laugh3',
  laugh3: 'laugh4'
  // beer: 'drink-beer',
  // coin: 'coin'
  // powerupThunder: 'thor1',
  // powerupMeat: 'meat1',
  // powerupBee: 'bees1',
  // berserk: 'berzerk',
  // dash: 'dash',
  // block: 'block',
  // boom: 'boom',
  // thor0: 'thunder1',
  // thor1: 'thunder2',
};

export class SoundPlayer {
  protected static sounds: SoundConfig<Howl> = {
    button0: SoundPlayer.prepareSound('button0')
  };

  protected static prepareSound(name: SoundEntry): Howl {
    const sample = soundConfig[name];

    return new Howl({
      src: [`./assets/sound/${sample}.ogg`, `./assets/sound/${sample}.mp3`],
      preload: true,
      pool: 1
    });
  }

  static prepareSounds(): void {
    Object.keys(soundConfig).forEach((name: SoundEntry) => {
      if (!SoundPlayer.sounds[name]) {
        SoundPlayer.sounds[name] = SoundPlayer.prepareSound(name);
      }
    });
  }

  static getPosition({
    scene: {
      player: {
        sprite: {
          worldTransform: { tx: offsetX, ty: offsetY }
        }
      }
    },
    sprite: {
      worldTransform: { tx: posX, ty: posY }
    }
  }: SoundSource): Vector {
    const x = (posX - offsetX) / innerWidth;
    const y = (posY - offsetY) / innerHeight;

    return { x, y };
  }

  static getVolume(distance: number): number {
    const max = Math.hypot(innerWidth, innerHeight) / 2;

    return Constants.MAX_VOLUME * (1 - Math.min(distance / max, 1));
  }

  static play(name: Sound, consumer?: AIConsumer): void {
    if (!detect.isFrontend) {
      return;
    }

    const soundSource = consumer as unknown as SoundSource;
    const { x, y } = consumer
      ? SoundPlayer.getPosition(soundSource)
      : { x: 0, y: 0 };
    const distance = consumer
      ? Math.hypot((x * innerWidth) / 2, (y * innerHeight) / 2)
      : 0;
    const volume = SoundPlayer.getVolume(distance);
    if (volume < Constants.MIN_SOUND_LEVEL) {
      return;
    }

    const { sounds } = SoundPlayer;
    const keys = Object.keys(sounds).filter((key) => key.startsWith(name));
    const sound = randomFrom(keys) as Sound;
    const howl = sounds[sound];

    if (howl.state() === 'unloaded') {
      howl.load();
    }

    howl.volume(volume);
    howl.stereo(Math.min(1, Math.max(-1, x)));
    howl.play();
  }
}
