import * as PIXI from 'pixi.js';

import { Constants, animatorAnchor, animatorConfig } from '../utils/constants';
import {
  GameObject,
  Lifecycle,
  LifecycleParent,
  LifecycleProps,
  StateMachine
} from '@pietal.dev/engine';

import { AnimationConfig } from '../utils/model';
import { Subject } from 'rxjs/internal/Subject';
import { TiledUtils } from './tiled-utils';
import { Vector } from 'detect-collisions';
import { createLabel } from '../utils';

export class MyAnimator extends PIXI.Container implements LifecycleProps {
  readonly update$: Subject<number> = new Subject();
  readonly destroy$: Subject<void> = new Subject();
  readonly complete$: Subject<string> = new Subject();
  readonly stateMachine: StateMachine;
  readonly sprite: PIXI.Container;

  gameObject: LifecycleParent;
  label: string;
  states: string[] = [];
  animation?: PIXI.AnimatedSprite;

  get state$(): Subject<string> {
    return this.stateMachine.state$;
  }

  get state(): string {
    return this.stateMachine.state;
  }

  get scale(): PIXI.ObservablePoint | undefined {
    return this.animation?.scale;
  }

  constructor(
    gameObject: GameObject,
    textureAtlas = TiledUtils.getInstance().textureAtlas
  ) {
    super();
    gameObject.addChild(this);

    this.sortableChildren = false;
    this.label = createLabel(gameObject.label, gameObject);
    this.stateMachine = new StateMachine(gameObject);

    const animations: AnimationConfig = animatorConfig[gameObject.label];
    const anchor: Vector = animatorAnchor[gameObject.label];

    if (!anchor || !animations) {
      console.error(`missing anchor/animations for: ${gameObject.label}`);
    } else {
      Object.entries(animations).forEach(([animation, animationFrames]) => {
        const animatedSprite = new PIXI.AnimatedSprite(
          animationFrames.map((frame) => {
            const texture = textureAtlas.get(frame);

            return { texture, time: Constants.ANIM_SPEED };
          })
        );

        animatedSprite.anchor.set(anchor.x, anchor.y);
        animatedSprite.label = `${gameObject.label}_${animation}`;
        animatedSprite.zIndex = 0;

        this.addChild(animatedSprite);
      });

      this.states = Object.keys(animations);
      this.setState(process.env.STATE || 'idle');
    }
  }

  /**
   * @param deltaTime = 1.0 for 60FPS
   */
  update(deltaTime: number): void {
    this.x = this.gameObject.x;
    this.y = this.gameObject.y;
    Lifecycle.update(this, deltaTime);
  }

  setScale(x = 1, y: number = x): void {
    (this.children as PIXI.AnimatedSprite[]).forEach(
      (child: PIXI.AnimatedSprite) => {
        child.scale.set(x, y);
      }
    );
  }

  getAnimationIndex(state: string): number {
    const exactIndex: number = this.getExactStateIndex(state);

    return exactIndex !== -1 ? exactIndex : this.getFuzzyStateIndex(state);
  }

  setAnimation(animation: PIXI.AnimatedSprite, loop: boolean): void {
    if (animation === this.animation) {
      return;
    }

    const children = this.children.filter(
      (child: PIXI.AnimatedSprite) =>
        child instanceof PIXI.AnimatedSprite && child !== animation
    );

    children.forEach((child: PIXI.AnimatedSprite) => {
      child.visible = false;
      child.stop();
    });

    animation.loop = loop;
    animation.gotoAndPlay(0);
    animation.visible = true;
    this.animation = animation;
  }

  setState(state: string, loop = true, stateWhenFinished = 'idle'): string {
    if (state === this.state) {
      return state;
    }

    const index: number = this.getAnimationIndex(state);
    if (index === -1) {
      return '';
    }

    const next = this.states[index];
    if (!this.stateMachine.setState(next)) {
      return '';
    }

    const animation = this.children[index] as PIXI.AnimatedSprite;
    if (!loop && stateWhenFinished) {
      animation.onComplete = () => {
        animation.onComplete = null;
        this.complete$.next(next);
        this.setState(stateWhenFinished);
      };
    }

    this.setAnimation(animation, loop);

    return next;
  }

  protected getExactStateIndex(state: string): number {
    return this.states.indexOf(state);
  }

  protected getFuzzyStateIndex(state: string): number {
    const indexes: number[] = this.states
      .map((direction: string, index: number) => ({
        direction,
        index
      }))
      .filter(({ direction }) => direction.toLocaleLowerCase().includes(state))
      .map(({ index }) => index);

    // random of above candidates
    return indexes.length
      ? indexes[Math.floor(indexes.length * Math.random())]
      : -1;
  }
}
