import { CircleBody, GameObject } from '@pietal.dev/engine';

import { BodyOptions } from 'detect-collisions';
import { Constants } from '../utils/constants';

export class EllipseBody extends CircleBody {
  constructor(
    gameObject: GameObject,
    radius = Constants.DEFAULT_RADIUS,
    bodyOptions: BodyOptions = {}
  ) {
    const radiusX = radius;
    const radiusY = radius * Constants.RADIUS_Y;
    const step = radius * Constants.STEP_MULTIPLIER;
    const padding = bodyOptions.isStatic
      ? 0
      : radius * Constants.PADDING_MULTIPLIER;

    super(gameObject, radiusX, radiusY, step, {
      padding,
      ...bodyOptions
    });
  }
}
